import Nav from "../components/Nav/Nav";

import SolutionsBody from "../components/Solutions/SolutionsBody";
import Footer from "../components/Footer/Footer";
import CTA from "../components/CTA/CTA";
import Banner from "../components/Banner/Banner";
import { BuildImageURL, GetPublicId } from "../utils/ImageBuilder";
import { useEffect } from "react";

import ScrollToTopButton from "../components/Scroll/ScrollToTopButton";

const Solutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav
        navClassName="absolute bg-white bg-opacity-70 backdrop-blur text-black"
        navContainerClassName="my-[25px] mx-[60px]"
      />
      <Banner page="Solutions" bannerType="solutions-banner" />
      <SolutionsBody />
      <CTA />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Solutions;
