import { buildUrl } from "cloudinary-build-url";
import { extractPublicId } from "cloudinary-build-url/dist/cjs";

export const BuildImageURL = (publicId) => {
  const buildImageUrl = buildUrl(publicId, {
    cloud: {
      cloudName: "dvfu8fxlg",
    },
  });

  return buildImageUrl;
};

export const GetPublicId = (imageUrl) => {
  const publicId = extractPublicId(imageUrl);
  return publicId;
};
