import Table from "./Table";
const Gallery = ({ galleryData }) => {
  return (
    <>
      {galleryData?.map((gallery, key) => (
        <Table gallery={gallery} key={key} />
      ))}
    </>
  );
};

export default Gallery;
