import { createContext, useState, useContext } from "react";
import { BuildImageURL, GetPublicId } from "../utils/ImageBuilder";
const testImageUrl =
  "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685372950/WinM_Assets/sol_test6_dp6yby.png";

const publicId = GetPublicId(testImageUrl);
const finalmageUrl = BuildImageURL(publicId);
const solutions_1 = finalmageUrl;

const AccomplishmentsContext = createContext({
  activeAccomplishment: null,
});

const AccomplishmentsProvider = ({ children }) => {
  const [accomplishmentState, setAccomplishmentState] = useState({
    activeAccomplishment: null,
  });

  const setActiveAccomplishment = (accomplishment) => {
    setAccomplishmentState((state) => ({
      ...state,
      activeAccomplishment: accomplishment,
    }));
  };

  return (
    <AccomplishmentsContext.Provider
      value={{
        ...accomplishmentState,
        setActiveAccomplishment,
      }}
    >
      {children}
    </AccomplishmentsContext.Provider>
  );
};

const Accomplishments = [
  {
    sector_name: "Hotels & Motels",
    projects: [
      {
        images: [
          {
            sol: "WINM102",
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534709/WinM_Assets/accomplishments/acc-hm-winm102_tthopn.jpg",
          },
          {
            sol: "WINM102",
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534709/WinM_Assets/accomplishments/acc-hm-winm102-2_tphrwp.jpg",
          },
        ],
      },
      {
        images: [
          {
            sol: "WINM102",
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691917321/WIM_MC_PHOTOS_2023-9104_room2d.jpg",
          },
          {
            sol: "WINM102",
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691917392/WIM_MC_PHOTOS_2023-9115_ywvrny.jpg",
          },
        ],
      },
      {
        images: [
          {
            sol: "WINM102",
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691917604/WINM_102_3_oxnk58.jpg",
          },
          {
            sol: "WINM102",
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691917595/WINM_102_1__1_wxkrxn.jpg",
          },
          {
            sol: "WINM102",
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691917594/WINM_102_2_ossln7.jpg",
          },
        ],
      },
    ],
  },
  {
    sector_name: "Convention Centers",
    projects: [
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534711/WinM_Assets/accomplishments/acc-cc-wapa_xu4zto.jpg",
            sol: "Wall Accents Polyester Acoustic",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691918058/WIM_LENVIOSA_PHOTOS_2023-2_ygudl6.jpg",
            sol: "Wall Accents Polyester Acoustic",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691918052/WIM_LENVIOSA_PHOTOS_2023-8956_nfp4b3.jpg",
            sol: "Wall Accents Polyester Acoustic",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691918051/WIM_LENVIOSA_PHOTOS_2023-8979_foj5di.jpg",
            sol: "Wall Accents Polyester Acoustic",
          },
        ],
      },
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534709/WinM_Assets/accomplishments/acc-cc-wapa-1_tcbn3n.jpg",
            sol: "WINM89",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691918165/Copy_of_WINM_89_2_iqezui.jpg",
            sol: "WINM62",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691918161/Copy_of_WINM_89_3_ayfxrx.jpg",
            sol: "WINM62",
          },
        ],
      },
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534710/WinM_Assets/accomplishments/acc-cc-wapa-2_oirbox.jpg",
            sol: "WINM62",
          },
        ],
      },
    ],
  },
  {
    sector_name: "Telecommunication",
    projects: [
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534710/WinM_Assets/accomplishments/acc-t-pawa_gfobnv.jpg",
            sol: "Polyester Acoustical Wall System",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919317/Copy_of_POLYESTER_ACOUSTICAL_WALL_SYSTEM_1_lzle9f.jpg",
            sol: "Polyester Acoustical Wall System",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919127/Copy_of_POLYESTER_ACOUSTICAL_WALL_SYSTEM_5_vhzzbr.jpg",
            sol: "Polyester Acoustical Wall System",
          },
        ],
      },
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919497/Copy_of_POLYESTER_ACOUSTICAL_WALL_SYSTEM_6_1_duamz6.jpg",
            sol: "Polyester Acoustical Wall System",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534710/WinM_Assets/accomplishments/acc-t-pawa-1_mdfotp.jpg",
            sol: "Polyester Acoustical Wall System",
          },
        ],
      },
    ],
  },
  {
    sector_name: "Pharmaceutical",
    projects: [
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919644/Copy_of_WINM_102_1_pr83n8.jpg",
            sol: "WINM102",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919644/Copy_of_WINM_102_2_l9neew.jpg",
            sol: "WINM102",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919643/Copy_of_WINM_102_4_lccchk.jpg",
            sol: "WINM102",
          },
        ],
      },
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534710/WinM_Assets/accomplishments/acc-p-winm102-1_xebzra.jpg",
            sol: "Polyester Acoustical Wall System",
          },
        ],
      },
    ],
  },
  {
    sector_name: "Educational and Training Facilities",
    projects: [
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534709/WinM_Assets/accomplishments/acc-etf-winm102_qvks8v.jpg",
            sol: "WINM102",
          },
        ],
      },
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534709/WinM_Assets/accomplishments/acc-etf-winm102-winm62_ovdzdf.jpg",
            sol: "WINM102",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919798/Copy_of_WINM_62_4_f2i0ab.jpg",
            sol: "WINM102",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919798/Copy_of_WINM_62_3_h9flio.jpg",
            sol: "WINM102",
          },
        ],
      },
    ],
  },
  {
    sector_name: "Bank and Insurance",
    projects: [
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686534711/WinM_Assets/accomplishments/acc-bi-wf-winm89_pv5keh.jpg",
            sol: "Wall Fabric",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919880/Copy_of_WALL_FABRIC_1_pimh7f.jpg",
            sol: "Wall Fabric",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919879/Copy_of_WALL_FABRIC_9_dzg8jg.jpg",
            sol: "Wall Fabric",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919879/Copy_of_WALL_FABRIC_8_fzds4m.jpg",
            sol: "Wall Fabric",
          },
        ],
      },
      {
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686536309/WinM_Assets/accomplishments/acc-bi-wf-winm89-2_jln51s.jpg",
            sol: "WINM89",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919916/WINM_89_4_ykymsa.jpg",
            sol: "WINM89",
          },
        ],
      },
    ],
  },
  {
    sector_name: "Cinemas",
    projects: [
      {
        name: "Seating Concept",
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1686536495/WinM_Assets/accomplishments/acc-c-sc_wqmmvf.jpg",
            sol: "Seating Concept",
          },
        ],
      },
      {
        name: "Seating Concept",
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919985/viber_image_2022-03-04_16-35-17-715_f2sbmj.jpg",
            sol: "Seating Concept",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919985/viber_image_2022-03-04_16-35-17-715_f2sbmj.jpg",
            sol: "Seating Concept",
          },
        ],
      },
      {
        name: "Seating Concept",
        images: [
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919985/viber_image_2022-03-04_16-28-33-772_u4bopm.jpg",
            sol: "Seating Concept",
          },
          {
            url: "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691919986/viber_image_2022-03-04_16-28-36-371_f21ci0.jpg",
            sol: "Seating Concept",
          },
        ],
      },
    ],
  },
];

const useAccomplishment = () => useContext(AccomplishmentsContext);

export { Accomplishments, useAccomplishment, AccomplishmentsProvider };
