import React, { useState } from "react";
// import Pasig from "../../images/testmap-pasig.PNG";
// import Cebu from "../../images/testmap-cebu.PNG";
import SmallPhone from "./SVG/SmallPhone";
import SmallMail from "./SVG/SmallMail";
import SmallLocation from "./SVG/SmallLocation";
import Maps from "../Maps/Maps";
import MapComponent from "../Maps/Maps";

const CUMaps = () => {
  const [showMap, setShowMap] = useState("pasig");

  const handleToggleMap = () => {
    setShowMap((prevMap) => (prevMap === "pasig" ? "cebu" : "pasig"));
  };

  return (
    <div className="bg-[#F7FAFC] py-[90px] px-[120px] mobile:py-[60px] mobile:px-[20px]">
      <div className="flex flex-row mobile:flex-col mobile:gap-[40px]">
        <div className="flex-1">
          {showMap === "pasig" ? (
            <Maps site={"pasig"} />
          ) : (
            <Maps site={"cebu"} />
          )}
        </div>
        <div className="flex-1 flex flex-col pl-[120px] gap-[30px] my-auto mobile:pl-0">
          <div className="flex flex-col gap-[20px]">
            <div className="text-[36px] leading-[39px] tracking-[-0.03em]">
              Our <span className="text-[#016EB8]">Offices</span>
            </div>
            <div className="text-[20px] leading-[23px] tracking-[-0.03em]">
              Walls in Motion Pasig – Main
            </div>
            <div className="flex flex-col gap-[15px]">
              <div className="flex flex-row gap-[20px]">
                <div className="my-auto">
                  <SmallPhone />
                </div>
                <div className="flex flex-row gap-[10px]">
                  <span>02 8424 3370 to 9</span>
                  <span>|</span>
                  <span> +63 917 629 8847</span>
                </div>
              </div>
              <div className="flex flex-row gap-[20px]">
                <div className="my-auto">
                  <SmallMail />
                </div>
                <div className="">marketing@wallsinmotion.com</div>
              </div>
              <div className="flex flex-row gap-[20px]">
                <div className="my-auto">
                  <SmallLocation />
                </div>
                <div
                  className={
                    showMap === "pasig"
                      ? "hover:cursor-text"
                      : "text-[#016EB8] hover:cursor-pointer hover:underline"
                  }
                  onClick={showMap === "pasig" ? null : handleToggleMap}
                >
                  No. 36 Eulogio Amang Rodriguez, Brgy. Rosario, Pasig City 1609
                  Metro Manila
                </div>
              </div>
            </div>
          </div>
          <div className="border-[1px] border-[#DDDDDD] "></div>
          <div className="flex flex-col gap-[30px]">
            <div className="text-[20px] leading-[23px] tracking-[-0.03em]">
              Walls in Motion Cebu
            </div>
            <div className="flex flex-col gap-[15px]">
              <div className="flex flex-row gap-[20px]">
                <div className="my-auto">
                  <SmallPhone />
                </div>
                <div className="flex flex-row gap-[10px]">
                  <span>+03 2564 2830</span>
{/*                   <span>|</span>
                  <span>+03 1838 8350</span> */}
                </div>
              </div>
              <div className="flex flex-row gap-[20px]">
                <div className="my-auto">
                  <SmallMail />
                </div>
                <div className="">cebu@wallsinmotion.com</div>
              </div>
              <div className="flex flex-row gap-[20px]">
                <div className="my-auto">
                  <SmallLocation />
                </div>
                <div
                  className={
                    showMap === "cebu"
                      ? "hover:cursor-text"
                      : "text-[#016EB8] hover:cursor-pointer hover:underline"
                  }
                  onClick={showMap == "cebu" ? null : handleToggleMap}
                >
                  Osmeña Village, Block 2 Manuel L. Quezon Street Casuntingan, Mandaue City Cebu
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CUMaps;
