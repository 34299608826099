import PauseSVG from "../Banner/PauseSVG";
import PlaySVG from "../Banner/PlaySVG";
const HeroAUVideoButton = ({ isPlaying }) => {
  return (
    <div className="hero-au-btn w-16 h-16 border-[1.5px] border-white rounded-full flex hover:border-[3px]">
      {isPlaying ? <PauseSVG /> : <PlaySVG />}
    </div>
  );
};

export default HeroAUVideoButton;
