import SolutionsImages from "./SolutionsImages";
const SolutionsGallery = ({ galleryData }) => {
  const firstThree = galleryData.slice(0, 3);
  return (
    <>
      {firstThree?.map((gallery, key) => (
        <SolutionsImages gallery={gallery} key={key} />
      ))}
    </>
  );
};

export default SolutionsGallery;
