import PlaySVG from "./PlaySVG";
import PauseSVG from "./PauseSVG";

const HeroVideoButton = ({ onTogglePlayPause, isPlaying }) => {
  const handleClick = () => {
    onTogglePlayPause(); // Call the onTogglePlayPause function to play or pause the video
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={`w-16 h-16 border-white rounded-full flex border-[1.5px] hover:border-[3px] `}
      >
        {isPlaying ? <PauseSVG /> : <PlaySVG />}
      </button>
    </>
  );
};

export default HeroVideoButton;
