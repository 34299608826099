const PrevArrow = (props) => {
  return (
    <div
      className="rounded-[5px] w-[45px] h-[45px] bg-[#2F2F2F] flex ymal-left"
      onClick={props.onClick}
    >
      <svg
        className="my-auto mx-auto"
        width="20"
        height="10"
        viewBox="0 0 20 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.75 1L1 4.75M1 4.75L4.75 8.5M1 4.75H19"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PrevArrow;
