import DefaultButton from "../../Buttons/DefaultButton";
import SolutionsGallery from "./SolutionsGallery";
import { Solutions } from "../../../contexts/SolutionsContext";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
const HeroSolutions = () => {
  return (
    <>
      <div className="bg-[#F7FAFC] px-[120px] py-[90px] mobile:px-[20px] mobile:py-[60px]">
        <div className="container-fluid flex flex-col">
          <Fade top delay={600}>
            <div className="solutions-header text-[42px] leading-[45px] text-center mobile:text-[26px] mobile:leading-[29px]">
              Efficient Space Utilization{" "}
              <span className="text-[#016EB8]">Made Easy</span>
            </div>
          </Fade>
          <Fade bottom delay={900}>
            <div className="solutions-gallery flex flex-wrap flex-row gap-[16px] mt-[50px]">
              <SolutionsGallery galleryData={Solutions} />
            </div>
          </Fade>
          <Fade bottom delay={1000}>
            <div className="solutions-button mt-[60px] mx-auto">
              <Link to="/solutions">
                <DefaultButton color="black">View All Solutions</DefaultButton>
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default HeroSolutions;
