import Mission from "./SVG/Mission";
import Values from "./SVG/Values";
import Vision from "./SVG/Vision";
import Fade from "react-reveal/Fade";

const AUValues = () => {
  return (
    <div className="py-[90px] px-[120px] bg-[#F2F8FC] laptop:py-[60px] mobile:px-[20px]">
      <div className="flex flex-row gap-[40px] laptop:flex-col mobile:gap-[40px]">
        <div className="flex-1 flex flex-col my-auto gap-[45px]">
          <Fade delay={300}>
            <div className="flex flex-col gap-[18px]">
              <div className="flex flex-row gap-[20px]">
                <div className="">
                  <Vision />
                </div>
                <div className="text-[32px] leading-[35px] tracking-[-0.03em]">
                  Vision
                </div>
              </div>
              <div className="text-[15px] leading-[23px] tracking-[-0.03em]">
                To Achieve Market Leadership in the Philippines and Establish a
                Presence in the Global Market.
              </div>
            </div>
          </Fade>
          <Fade delay={400}>
            <div className="flex flex-col gap-[18px]">
              <div className="flex flex-row gap-[20px]">
                <div className="">
                  <Mission />
                </div>
                <div className="text-[32px] leading-[35px] tracking-[-0.03em]">
                  Mission
                </div>
              </div>
              <div className="text-[15px] leading-[23px] tracking-[-0.03em]">
                To Provide Excellent Service through Quality & Diverse Space
                Management Solutions.
              </div>
            </div>
          </Fade>
          <Fade delay={500}>
            <div className="flex flex-col gap-[18px]">
              <div className="flex flex-row gap-[20px]">
                <div className="">
                  <Values />
                </div>
                <div className="text-[32px] leading-[35px] tracking-[-0.03em]">
                  Values
                </div>
              </div>
              <div className="text-[15px] leading-[23px] tracking-[-0.03em]">
                Integrity, Mindfulness, and Teamwork
              </div>
            </div>
          </Fade>
        </div>
        <div className="flex-1 ">
          <Fade right delay={600} distance="20%">
            <img
              src="https://res.cloudinary.com/dvfu8fxlg/image/upload/v1688441028/WinM_Assets/au_vmv_x4bk7e.jpg"
              className="w-full"
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default AUValues;
