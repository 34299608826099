const ClientSlide = ({ slide }) => {
  // console.log(slide)
  return (
    <>
      <div className="border-[1px] border-solid border-[#FFFFFF] h-[350px]rounded-xl p-[12px]">
        <div className="border-[1px] border-solid border-[#EAEAEA] rounded-xl h-full w-full flex flex-col gap-[15px]  py-[30px] px-[80px] mobile:px-[20px]">
          <div className="client-quotes flex-1 flex flex-row gap-[6px] justify-center">
            <svg
              width="13"
              height="25"
              viewBox="0 0 13 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.249329 12.4967V25L6.50748 18.7419L12.7656 12.4837V6.24511V0H6.50748H0.249329V12.4967Z"
                fill="#016EB8"
              />
            </svg>
            <svg
              width="13"
              height="25"
              viewBox="0 0 13 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.249329 12.4967V25L6.50748 18.7419L12.7656 12.4837V6.24511V0H6.50748H0.249329V12.4967Z"
                fill="#016EB8"
              />
            </svg>
          </div>
          <div className="client-testi flex flex-col gap-[15px]">
            <span className="text-[15px] leading-[23px] text-center">
              {slide.testimonial}
            </span>
            <span className="text-[#016EB8] text-[13px] leading-[23px] flex flex-row text-center justify-center">
              — {slide.client_name}, {slide.client_title}
            </span>
          </div>
          <div className="client-logo flex-1">
            <img src={slide.img_url} className="mx-auto " />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSlide;
