import { useState } from "react";
import { formBg } from "../../../utils/Images";
import DefaultButton from "../../Buttons/DefaultButton";
import Fade from "react-reveal/Fade";
import SendEmail from "../../../api_functions/SendEmail";


const bg = formBg;

const Partner = () => {

  const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState('')
    const [formError,setFormError] = useState(false)
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [partnership,setPartnership] = useState('');
    const [message,setMessage] = useState('');

    const [errors,setErrors] = useState({name:'',email: '', phone: '', partnership: '', message: ''});

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleEmailChange = (e) => {
      setEmail(e.target.value)
    }

    const handlePhoneChange = (e) => {
      setPhone(e.target.value)
    }

    const handlePartnershipChange = (e) => {
      setPartnership(e.target.value)
    }

    const handleMessageChange = (e) => {
      setMessage(e.target.value)
    }

    const setFieldError = (fieldName, errorMessage) => {
      setErrors((prevErrors) => {
        const updatedErrors = {...prevErrors};
        updatedErrors[fieldName] =  errorMessage
        return updatedErrors
      });
    };
    const resetFieldErrors = () => {
      setErrors((prevErrors) => {
        const updatedErrors = {};      
        Object.keys(prevErrors).forEach((key) => {
          updatedErrors[key] = '';
        });
      
        return updatedErrors;
      });
    };

    const resetFormFields = () => {
      setName('');
      setEmail('');
      setPhone('');
      setPartnership('');
      setMessage('');
    }

    const getErrorsCount = () => {
      const count =  Object.values(errors).filter(error => error !== '').length;
      return count;
    }

    const validateForm = () => {

      var error = false;
    
      if (!name.trim()) {
        setFieldError('name', 'Name is required.')
        error = true;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setFieldError('email', 'A valid email address required e.g. you@example.com');
        error = true;
      }

      const phoneRegex = /^[0-9+]+$/;
      if (!phoneRegex.test(phone.trim())) {
        setFieldError('phone', 'A valid phone number required e.g. +631234567890');
        error = true;
      }

      if(!partnership.trim()) {
        setFieldError('partnership', 'Please select an option.')
        error = true;
      }

      if(!message.trim()) {
        setFieldError('message', 'Message is required.')
        error = true;
      }
      return error
    }



    const handleFormSubmission = async() => {
      setSubmitting(true)
      setFormMessage('');
      setFormError(false)
      resetFieldErrors();
     
      if(validateForm()){
        setFormMessage('Invalid inputs, please correct the errors!')
        setSubmitting(false)
      }else{
        
        const formData = {
          name: name.trim(),
          email:email.trim(),
          phone:phone.trim(),
          partnership:partnership.trim(),
          message:message.trim(),
          type:'partner'
        }
        try {
          const result = await SendEmail(false,formData);
          if (result.success) {
            setFormMessage('Form has been submitted successfully!');
            resetFormFields()
          } else {
            if(result.type==='error'){
              setFormMessage(result.message);
              setFormError(true)
            }else if(result.type==='errors'){
              setErrors(result.message);
              setFormMessage('Invalid inputs, please correct the errors!');
            }else{
              setFormError(true)
              setFormMessage('Something went wrong. Try again!');
            }
            
          }
        } catch (error) {
          setFormMessage(error.message);
          setFormError(true)
        }
        
        setSubmitting(false)
      }
    }

  return (
    <div
      className="form-partner px-[220px] py-[90px] tablet:px-[120px] mobile:px-[20px] mobile:py-[60px]"
      style={{ backgroundImage: `url(${bg})` }}
      id="partner_form"
    >
      <div className="flex flex-col  gap-[40px]">
        <div className="flex flex-col text-white">
          <Fade top>
            <div className="text-[36px] leading-[39px]  text-center justify-center align-center mobile:text-left">
              Partner with us <span className="text-[#016EB8]">today!</span>
            </div>
          </Fade>
          <Fade>
            <div className="text-[15px] leading-[23px] tracking-[-0.03em] mt-[20px] text-center justify-center align-center mobile:text-left"></div>
          </Fade>
          { formMessage ? <div className={`p-5 text-base text-white rounded mt-4 w-full mx-auto ${getErrorsCount() > 0 || formError ? 'bg-red-500' : 'bg-green-500'}`}>{formMessage}</div> : ''  }
        </div>
        <Fade delay={500}>
          <div className="flex flex-col  gap-[16px]">
            <div className="flex flex-row  gap-[16px] tablet:flex-col">
              <div className="flex flex-col flex-1 text-white gap-[12px]">
                <div className=" text-white">
                  Name <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <input
                    type="text"
                    placeholder="Your full name"
                    className={`border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent text-white ${errors.name ? 'border-red-500' : ''}`}
                    value={name}
                    onChange={handleNameChange}
                    
                  />
                  {errors.name ? <div className="text-sm text-red-500 mt-1">{errors.name}</div> : ''}
                </div>
              </div>
              <div className="flex flex-col flex-1 text-white gap-[12px]">
                <div className=" text-white ">
                  Email Address <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <input
                    type="text"
                    placeholder="Your email address"
                    className={`border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent text-white ${errors.email ? 'border-red-500' : ''}`}
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {errors.email ? <div className="text-sm text-red-500 mt-1">{errors.email}</div> : ''}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-[16px] tablet:flex-col">
              <div className="flex flex-col  flex-1 text-white gap-[12px]">
                <div className=" text-white">
                  Phone Number <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <input
                    type="text"
                    placeholder="e.g +631234567890"
                    className={`border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent text-white ${errors.phone ? 'border-red-500' : ''}`}
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  {errors.phone ? <div className="text-sm text-red-500 mt-1">{errors.phone}</div> : ''}
                </div>
              </div>
              <div className="flex flex-col flex-1 text-white gap-[12px]">
                <div className=" text-white">
                  Select Partnership <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <div className="relative">
                    <select
                      placeholder="Select an option"
                      className={`border-[1px]  px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent my-auto appearance-none text-white ${errors.partnership ? 'border-red-500' : ''}`}
                      value={partnership}
                      onChange={handlePartnershipChange}
                    >
                      <option className="text-opacity-50" value="" disabled>
                        Select an option
                      </option>
                      <option
                        className="text-black hover:bg-black"
                        value="Sales Agent"
                      >
                        Sales Agent
                      </option>
                      <option className="text-black" value="Distributor">
                        Distributor
                      </option>
                    </select>

                    <div className="text-white absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <svg
                        width="15"
                        height="10"
                        viewBox="0 0 15 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=""
                      >
                        <path
                          d="M13 2L7.16667 9L1.33333 2"
                          stroke="#FFFFFF"
                          strokeWidth="1.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                          className="text-black text-opacity-50"
                        />
                      </svg>
                    </div>
                  </div>
                  {errors.partnership ? <div className="text-sm text-red-500 mt-1">{errors.partnership}</div> : ''}
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 text-white gap-[12px]">
              <div className="text-white">
                Message <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <textarea
                  type="text"
                  placeholder="Your message here"
                  className={`px-[18px] py-[12px] border-[1px] border-solid w-full rounded  focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent text-white ${errors.message ? 'border-red-500' : ''}`}
                  value={message}
                  onChange={handleMessageChange}
                />
                {errors.message ? <div className="text-sm text-red-500 mt-1">{errors.message}</div> : ''}
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom delay={400}>
          <div className="mx-auto">
            <DefaultButton onClick={handleFormSubmission} customClasses={submitting ? 'pointer-events-none opacity-50' : ''}>{submitting ? 'Submitting...' : 'Submit'}</DefaultButton>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Partner;
