import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Banner = ({ page, bannerType }) => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");

  useEffect(() => {
    let bannerUrl = "";

    switch (bannerType) {
      case "solutions-banner":
        //Solutions Page
        bannerUrl =
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1688797179/WinM_Assets/headers/h-solutions_e7ehok.jpg";
        break;
      case "acc-banner":
        //Accomplishments Page
        bannerUrl =
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1688797153/WinM_Assets/headers/h-accomplishments_pmiuoi.jpg";
        break;
      case "partner-banner":
        //Partner Page
        bannerUrl =
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1703813935/partnership_orxw1t.jpg";
        break;
      case "careers-banner":
        //Careers Page
        bannerUrl =
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1688797223/WinM_Assets/headers/h-careers_fohzjh.jpg";
        break;
      case "au-banner":
        //About Us Page
        bannerUrl =
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1688797155/WinM_Assets/headers/h-au_ordlsb.jpg";
        break;
      case "contactus-banner":
        //Contact us Page
        bannerUrl =
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1688797156/WinM_Assets/headers/h-cu_oe7r9d.jpg";
        break;
      default:
        break;
    }

    if (bannerUrl) {
      setBackgroundImageUrl(bannerUrl);
    }
  }, [bannerType]);

  return (
    <>
      <div
        className={`banner bg-center w-full bg-[#C5C5C5] relative ${bannerType}`}
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        {/* Background Overlay with Blur */}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 backdrop-blur-md z-0"></div>

        {/* Text Container */}
        <div className="flex flex-col pt-[370px] px-[120px] pb-[80px] text-white mobile:px-[20px] mobile:pt-[155px] mobile:pb-[40px] relative z-[999]">
          <Fade left>
            <div className="flex text-[62px] leading-[81px] tracking-[-0.03em] sm-mobile:text-[32px]">
              {page}
            </div>
          </Fade>
          <Fade delay={300}>
            <div className="flex flex-row gap-[5px] text-[15px]">
              <div className="text-white text-opacity-50 hover:underline hover:text-white">
                <Link to="/">Home</Link>
              </div>
              <div className="text-white text-opacity-50">/</div>
              <div className="">{page}</div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default Banner;
