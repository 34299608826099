import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import PreviewSlide from "./Slider/PreviewSlide";
import NavSlide from "./Slider/NavSlide";
import { Milestones } from "../../contexts/MilestoneContext";
import Fade from "react-reveal/Fade";

const AUMilestones = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const navSliderRef = useRef(null);
  const prevSliderRef = useRef(null);

  const previewSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    fade: true,
    afterChange: (currentSlide) => setActiveSlide(currentSlide),
    arrows: false,
  };

  const navSettings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    afterChange: (currentSlide) => setActiveSlide(currentSlide),
    arrows: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  // useEffect(() => {
  //   // Accessing the Slick instances using the refs
  //   const navSlider = navSliderRef.current;
  //   const prevSlider = prevSliderRef.current;

  //   // Syncing the sliders
  //   if (navSlider && prevSlider) {
  //     navSlider.slickGoTo(activeSlide);
  //     prevSlider.slickGoTo(activeSlide);
  //   }
  // }, [activeSlide]);

  return (
    <div className="w-full my-[90px] flex flex-col gap-[60px] mobile:my-[60px]">
      <Fade top delay={400}>
        <div className="text-[36px] leading-[39px] tracking-[-0.03em] mx-auto">
          Our <span className="text-[#016EB8]">Milestones</span>
        </div>
      </Fade>
      <Fade delay={600}>
        <div className="">
          <Slider
            asNavFor={navSliderRef.current}
            ref={prevSliderRef}
            {...navSettings}
          >
            {Milestones?.map((slide, index) => (
              <NavSlide
                slide={slide}
                currentSlide={index}
                key={index}
                activeSlide={activeSlide}
              />
            ))}
          </Slider>
        </div>
      </Fade>

      <div className="">
        <Slider
          asNavFor={prevSliderRef.current}
          ref={navSliderRef}
          {...previewSettings}
        >
          {Milestones?.map((slide, key) => (
            <PreviewSlide slide={slide} key={key} activeSlide={activeSlide} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default AUMilestones;
