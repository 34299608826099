import Nav from "../components/Nav/Nav";
import Banner from "../components/Banner/Banner";
import BAPFirst from "../components/Partnership/BAPFirst";
import BAPSecond from "../components/Partnership/BAPSecond";
import BAPThird from "../components/Partnership/BAPThird";
import Footer from "../components/Footer/Footer";
import Forms from "../components/Forms/Forms";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ScrollToTopButton from "../components/Scroll/ScrollToTopButton";

const Partnerships = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.state || !location.state.scrollToBody) {
      window.scrollTo(0, 0);
    }
  }, [!location.state]);
  return (
    <>
      <Nav
        navClassName="absolute bg-white bg-opacity-70 backdrop-blur text-black"
        navContainerClassName="my-[25px] mx-[60px]"
      />
      <Banner page="Be A Partner" bannerType="partner-banner" />
      <BAPFirst scrollTo={(location.state && location.state.scrollToBody) ? location.state.scrollToBody : ''} />
      <BAPSecond />
      <BAPThird scrollTo={(location.state && location.state.scrollToPartnersForm) ? location.state.scrollToPartnersForm : ''} />
      <Forms formType="partner" />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Partnerships;
