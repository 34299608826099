import NavCircleLine from "./SVG/NavCircleLine";
import { useEffect } from "react";

const NavSlide = ({ slide, activeSlide }) => {
  const isSlideActive = activeSlide === slide.ms_id;
  const nextSlide = activeSlide + 1;
  const isNextSlide = nextSlide === slide.ms_id;

  return (
    <>
      <div className="flex flex-col nav-slide">
        <div className="mx-auto mb-[-7px] ">
          <button
            className={`${
              isSlideActive
                ? "bg-[#016EB8] text-white"
                : "bg-[#E3E6E8] text-black"
            } ${
              isNextSlide ? "ms-shadow" : ""
            } border-[#E3E6E8] border-[1px] rounded-[4px] px-[25px] py-[15px] whitespace-nowrap nav-slide-rect`}
          >
            {slide.ms_year}
          </button>
        </div>
        <NavCircleLine isActive={isSlideActive} />
        <div className="border-[8px] border-[#E3E6E8] relative bottom-[28px] z-0" />
      </div>
    </>
  );
};

export default NavSlide;
