import Nav from "../components/Nav/Nav";
import Banner from "../components/Banner/Banner";
import AUFirst from "../components/About Us/AUFirst";
import AUCTA from "../components/About Us/AUCTA";
import AUMilestones from "../components/About Us/AUMilestones";
import AUValues from "../components/About Us/AUValues";
import AUTeam from "../components/About Us/AUTeam";
import Footer from "../components/Footer/Footer";
import CTA from "../components/CTA/CTA";
import { useRef } from "react";
import { useEffect } from "react";
import ScrollToTopButton from "../components/Scroll/ScrollToTopButton";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav
        navClassName="absolute bg-white bg-opacity-70 backdrop-blur text-black"
        navContainerClassName="my-[25px] mx-[60px]"
      />
      <Banner bannerType="au-banner" page="About Us" />
      <AUFirst />
      <AUCTA />
      <AUMilestones />
      <AUValues />
      <AUTeam />
      <CTA />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default AboutUs;
