import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ClientSlide from "./ClientSlide";

const ClientSlider = ({ sliderData }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} className="flex">
        {sliderData?.map((slide, key) => (
          <ClientSlide slide={slide} key={key} />
        ))}
      </Slider>
    </>
  );
};

export default ClientSlider;
