import Phone from "./SVG/Phone";
import Mail from "./SVG/Mail";
import { useEffect } from "react";
import Fade from "react-reveal/Fade";
const CUBody = ({ scrollTo }) => {
  useEffect(() => {
    if (scrollTo) {
      const cuBody = document.getElementById("cu-body");
      if (cuBody) {
        cuBody.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [scrollTo]);

  return (
    <>
      <div
        className="flex px-[20px] flex-col py-[90px] gap-[50px] mobile:px-[20px] mobile:py-[60px]"
        id="cu-body"
      >
        <div className="flex flex-col  gap-[15px]">
          <Fade top delay={300}>
            <span className="text-[12px] leading-[0.2em] text-[#878787] uppercase text-center mobile:text-left">
              get in touch
            </span>
          </Fade>
          <Fade delay={500}>
            <div className="text-[42px] leading-[45px] justify-center items-center mx-auto mobile:ml-0">
              <div className="text-center mobile:w-full mobile:text-left">
                Have a question or need assistance?{" "}
                <span className="text-[#016EB8]">Reach us! </span>
              </div>
            </div>
          </Fade>
        </div>
        <div className="px-[200px] laptop:px-0 laptop:mx-auto mobile:ml-0">
          <div className="flex flex-row laptop:flex-col laptop:gap-[25px] mobile:gap-[25px]">
            <div className="flex-1 flex flex-row gap-[20px]">
              <Fade delay={300}>
                <div className="">
                  <Phone />
                </div>
              </Fade>

              <div className="flex flex-col gap-[5px] my-auto">
                <Fade right delay={500} distance="20%">
                  <div className="uppercase text-[13px] leading-[18px] tracking-[-0.03em] big-laptop:text-[12px]">
                    Contact Number
                  </div>
                </Fade>
                <Fade right delay={600} distance="20%">
                  <div className="text-[23px] leading-[23px] tracking-[-0.03em] hover:cursor-pointer hover:text-[#016EB8] hover:underline hover:decoration-[#016EB8] big-laptop:text-[18px] xsm-mobile:text-[15px]">
                    <a href="tel:+0284243370">+02 8424 3370 to 9</a>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="flex-1 flex flex-row gap-[20px]">
              <Fade delay={600} distance="20%">
                <div className="">
                  <Mail />
                </div>
              </Fade>

              <div className="flex flex-col gap-[5px] my-auto">
                <Fade right delay={800} distance="20%">
                  <div className="uppercase text-[13px] leading-[18px] tracking-[-0.03em] big-laptop:text-[12px]">
                    human resources
                  </div>
                </Fade>
                <Fade right delay={900} distance="20%">
                  <div className=" text-[23px] leading-[23px] tracking-[-0.03em] hover:cursor-pointer hover:text-[#016EB8] hover:underline hover:decoration-[#016EB8] big-laptop:text-[18px] xsm-mobile:text-[15px]">
                    <a href="mailto:hr@wallsinmotion.com">
                      hr@wallsinmotion.com
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="flex-1 flex flex-row gap-[20px]">
              <Fade delay={900}>
                <div className="">
                  <Mail />
                </div>
              </Fade>

              <div className="flex flex-col gap-[5px] my-auto">
                <Fade right delay={1100} distance="20%">
                  <div className="uppercase text-[13px] leading-[18px] tracking-[-0.03em] big-laptop:text-[12px]">
                    marketing
                  </div>
                </Fade>
                <Fade right delay={1200} distance="20%">
                  <div className="text-[23px] leading-[23px] tracking-[-0.03em] hover:cursor-pointer hover:text-[#016EB8] hover:underline hover:decoration-[#016EB8] big-laptop:text-[18px] xsm-mobile:text-[15px]">
                    <a href="mailto:sales_inquiry@wallsinmotion.com">
                      sales_inquiry@wallsinmotion.com
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CUBody;
