import { createContext, useState, useContext } from "react";
import { GetPublicId, BuildImageURL } from "../utils/ImageBuilder";

const Solutions = [
  {
    solution_id: 1,
    solution_name: "WINM62 Wall",
    solution_desc: "Ideal for work rooms and examination centers",
    solution_img:
      "https://res.cloudinary.com/dgukgozi7/image/upload/v1709187315/WINM_62_1_xlnftp.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709187315/WINM_62_1_xlnftp.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709187316/WINM_62_3_rm8pcf.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709188005/WINM_62_2_afisod.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709188005/WINM_62_111_bb5yt3.jpg",
      },
    ],
    solution_title: "Movable walls",
    solution_chars: [
      {
        sol_desc:
          "Recommended for low STC requirements up to 3 meters high ceiling",
      },
      {
        sol_desc:
          "An introductory movable wall system with the jack mechanism same as the WinM89® and WinM 102® variants.",
      },
      {
        sol_desc:
          "Customizable panel design with top-and-bottom retractable seals for enhanced privacy.",
      },
      {
        sol_desc: "Ideal for work rooms and examination centers.",
      },
    ],
  },
  {
    solution_id: 2,
    solution_name: "WINM89 Wall",
    solution_desc: "Ideal for training rooms, classrooms, and meetings rooms",
    solution_img:
      "https://res.cloudinary.com/dgukgozi7/image/upload/v1709188171/WINM_89_4_gwk6qw.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708743050/WINM_89_TIPSY_dlncjw.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708743049/MC_HOTEL_WINM89_qqebjy.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708743415/winm_89_1_cjjeib.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709188170/WINM_333_ydti0s.jpg",
      },
    ],
    solution_title: "Movable walls",
    solution_chars: [
      {
        sol_desc:
          "Achieved a 48 STC from an independent laboratory test using the ASTM E90-09 test method.",
      },
      {
        sol_desc:
          "Best for Medium to high panel requirements that require high-acoustic components and offers quality 89 mm thick panels and customizable panel design.",
      },
      {
        sol_desc: "Ideal for training rooms, classrooms, and meetings rooms.",
      },
    ],
  },
  {
    solution_id: 3,
    solution_name: "WINM102 Wall",
    solution_desc:
      "Ideal for hotel banquet rooms, convention centers, and multi-purpose halls.",
    solution_img:
      "https://res.cloudinary.com/dgukgozi7/image/upload/v1709189449/WINM_102_5_szokxo.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709189449/WINM_102_eemb5n.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709189449/WINM_102_2_qpbgke.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709189449/WINM_102_9_dun7qz.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709189449/WINM_102_6_rksqm1.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691370509/WinM_Assets/solutions/new%20winm%20102/Copy_of_WINM_102_8_nm0af4.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709189449/WINM_102_4_u94hte.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1709189449/WINM_102_7_bcdl4v.jpg",
      },
    ],
    solution_title: "Movable walls",
    solution_chars: [
      {
        sol_desc:
          "Impressive 55 STC certified by an independent laboratory test, utilizing ASTM E90-09 and ASTM E413 test methods. Sound test certificates came from STC 45, STC 51, STC 55",
      },
      {
        sol_desc:
          "Best for high-panel and high-acoustic performance requirements that offers customizable panel design and flexibility from standard to configurable automatic switch curved track.",
      },
      {
        sol_desc:
          "Ideal for hotel banquet rooms, convention centers, and multi-purpose halls.",
      },
    ],
  },
  {
    solution_id: 4,
    solution_name: "Accordion Door Partition",
    solution_desc:
      "Ideal for hospital examination rooms, dental laboratories, and cupboard spaces.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808320/WinM_Assets/solutions/sol_adp_rthpnp.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808320/WinM_Assets/solutions/sol_adp-1_kkeihe.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808320/WinM_Assets/solutions/sol_adp-2_tafmf6.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808321/WinM_Assets/solutions/sol_adp-3_qxre9y.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808320/WinM_Assets/solutions/sol_adp_rthpnp.png",
      },
    ],
    solution_title: "Partition",
    solution_chars: [
      {
        sol_desc: "Best for non-acoustic partitions, up to 3 meters high",
      },
      {
        sol_desc:
          "Quick and economical space divider with custom-made design varying from various laminated board finish.",
      },
      {
        sol_desc:
          "Ideal for hospital examination rooms, dental laboratories, and cupboard spaces.",
      },
    ],
  },
  {
    solution_id: 5,
    solution_name: "Accordion Soundfold",
    solution_desc: "Ideal for lecture rooms, churches, and congregation areas.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808321/WinM_Assets/solutions/sol_ads_ckjyke.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808321/WinM_Assets/solutions/sol_ads-1_dxtuab.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808321/WinM_Assets/solutions/sol_ads-2_rsq2ik.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808321/WinM_Assets/solutions/sol_ads_ckjyke.png",
      },
    ],
    solution_title: "Partition",
    solution_chars: [
      {
        sol_desc:
          "For double leaf diamond type partitions, up to 3.5 meters high with a high-performance accordion system consisting of double-wall insulated panels.",
      },
      {
        sol_desc:
          "Installed with top-to-bottom rubber sweeps for better sound control performance and customizable design.",
      },
      {
        sol_desc: "Ideal for lecture rooms, churches, and congregation areas.",
      },
    ],
  },
  {
    solution_id: 6,
    solution_name: "Demountable Partition - Porta Panel",
    solution_desc: "Ideal for schools, churches, restaurants, and offices.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_dppp_q7jaob.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_dppp-1_to9ue6.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_dppp_q7jaob.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_dppp-2_cscg3g.png",
      },
    ],
    solution_title: "PARTITION",
    solution_chars: [
      {
        sol_desc:
          "Portable, demountable, and wall-secured partition system, up to 3.5 meters high. And with customizable designs varying from fabric finish to laminated board finish",
      },
      {
        sol_desc:
          "Panels installed with self-leveling spring head channels engaged against the ceiling. Thus, no need for roller tracks and floor fasteners.",
      },
      {
        sol_desc: "Ideal for schools, churches, restaurants, and offices.",
      },
    ],
  },
  {
    solution_id: 7,
    solution_name: "G-45 Panel",
    solution_desc:
      "Ideal for outdoor dining areas, retail stores, ATM areas and office rooms.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691910347/G45_01_i1gm5c.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691910357/G45_02_ron0ng.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691910347/G45_01_i1gm5c.jpg",
      },
    ],
    solution_title: "ALUMINUM GLASS PANEL",
    solution_chars: [
      {
        sol_desc:
          "The G-45 Movable Glass Wall System combines the QuikwallⓇ line and the WinM® movable wall track system, which efficiently uses the floor space and provides a break from a run of solid panels, letting in natural light and promoting energy efficiency. ",
      },
      {
        sol_desc:
          "The G-45 Movable Glass Wall System also limits sound transmission through its top and bottom rubber sweeps",
      },
      {
        sol_desc:
          "Ideal for outdoor dining areas, retail stores, ATM areas, and office rooms.",
      },
    ],
  },
  {
    solution_id: 8,
    solution_name: "Slimline Panel",
    solution_desc:
      "Ideal for offices, conference room, and examination centers",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912084/SLIMLINE_EDITED_sfgfmn.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912084/SLIMLINE_EDITED_sfgfmn.jpg",
      },
    ],
    solution_title: "ALUMINUM GLASS PANEL",
    solution_chars: [
      {
        sol_desc:
          "The Quikwall Slimline Series has a lightweight profile of a slim 45mm x 25mm sill bead that can accept glasses ranging from 6mm to 12mm thick, supported by a friction-fit PVC wedge on both sides. ",
      },
      {
        sol_desc:
          "Eliminate the need for silicone sealant and provides easy glass installation.",
      },
      {
        sol_desc:
          "Ideal for offices, conference rooms, and examination centers",
      },
    ],
  },
  {
    solution_id: 9,
    solution_name: "Wideline Panel",
    solution_desc:
      "Ideal for offices, conference rooms, and examination centers",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912473/WIDELINE_EDITED_gttmix.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912473/WIDELINE_EDITED_gttmix.jpg",
      },
    ],
    solution_title: "ALUMINUM GLASS PANEL",
    solution_chars: [
      {
        sol_desc:
          "The Quikwall Wideline® Series consists of a 101mm x 25mm frame, wide enough to stand alone or in conjunction with a fixed or demountable solid wall. This product is best used with our Slimline and Relocatable Series to create a semi-permanent office space. ",
      },
      {
        sol_desc:
          "The standard wall thickness is 90mm using a 64mm steel track with studs and a 13mm gypsum board.",
      },
      {
        sol_desc:
          "Ideal for offices, conference rooms, and examination centers",
      },
    ],
  },
  {
    solution_id: 10,
    solution_name: "Double Glazed Panel",
    solution_desc: "Ideal for any wall construction or installation project",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692694813/WinM_Assets/solutions/DOUBLEGLAZEDMOVABLE_02_jjfrpe.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692694813/WinM_Assets/solutions/DOUBLEGLAZEDMOVABLE_02_jjfrpe.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692694811/WinM_Assets/solutions/DOUBLEGLAZEDMOVABLE_03_fekppy.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692694808/WinM_Assets/solutions/DOUBLEGLAZEDMOVABLE_04_okr422.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692694808/WinM_Assets/solutions/DOUBLEGLAZEDMOVABLE_01_xyqxe1.jpg",
      },
    ],
    solution_title: "ALUMINUM GLASS PANEL",
    solution_chars: [
      {
        sol_desc:
          "Recommended in environments with harsh weather conditions for maximum insulation.",
      },
      {
        sol_desc: "Seals and maintains a buffer from the outside.",
      },
      {
        sol_desc: "Ideal for any wall construction or installation projects",
      },
    ],
  },
  {
    solution_id: 11,
    solution_name: "Wall Accessories",
    solution_desc: "Ideal for any wall construction or installation project",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808317/WinM_Assets/solutions/sol_wa_m1tvnr.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808317/WinM_Assets/solutions/sol_wa_m1tvnr.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808317/WinM_Assets/solutions/sol_wa-2_mxexwz.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808317/WinM_Assets/solutions/sol_wa-1_xtgozj.png",
      },
    ],
    solution_title: "ACCESSORIES",
    solution_chars: [
      {
        sol_desc:
          "Our space experts will work closely with you to recommend diverse selections, integrate ideal materials & components, and provide durable systems that will last long term aligned with the client's requirements and design.",
      },
      {
        sol_desc: "Ideal for any wall construction or installation project",
      },
    ],
  },
  {
    solution_id: 12,
    solution_name: "Quiktrak Wall System",
    solution_desc:
      "Ideal for hallways, offices, cinemas and other confidential areas.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808315/WinM_Assets/solutions/sol_qw_clmpgn.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808317/WinM_Assets/solutions/sol_wa_m1tvnr.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808317/WinM_Assets/solutions/sol_wa-2_mxexwz.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808317/WinM_Assets/solutions/sol_wa-1_xtgozj.png",
      },
    ],
    solution_title: "ACOUSTIC wall system",
    solution_chars: [
      {
        sol_desc:
          "Make use of a patented product that provides a method of utilizing fabric as an architectural finish. The frameworks used are rigid vinyl locking channels with a square and radius edge Quiktrak.",
      },
      {
        sol_desc:
          "The Quiktrak framework & subsurface will remain in place and ensure no damage to the framework when installing and changing the fabric.",
      },
      {
        sol_desc:
          "Reduce high-frequency noise levels commonly in hallways, offices, cinemas, and other confidential areas.",
      },
    ],
  },
  {
    solution_id: 13,
    solution_name: "Wall Padded",
    solution_desc:
      "Ideal for wall systems as decorative and provides insulation.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808316/WinM_Assets/solutions/sol_qw-2_qze7v3.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808316/WinM_Assets/solutions/sol_qw-2_qze7v3.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692695211/WinM_Assets/solutions/PADDEDWALL_02_xrtsmv.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692695213/WinM_Assets/solutions/PADDEDWALL_03_axeei3.jpg",
      },
    ],
    solution_title: "ACOUSTIC wall system",
    solution_chars: [
      {
        sol_desc: "Simple and quick installation.",
      },
      {
        sol_desc:
          "A wall that is clad with padding and uses different finishes based on the client's preference.",
      },
      {
        sol_desc:
          "Ideal for wall systems as decorative and provides insulation.",
      },
    ],
  },
  {
    solution_id: 14,
    solution_name: "Quiktrak Acoustics",
    solution_desc:
      "Ideal for theatre, cinemas, civic centers, and home theaters",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692695419/WinM_Assets/solutions/ACCOUSTICWALLSYSTEM_01_ppbzrf.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692695419/WinM_Assets/solutions/ACCOUSTICWALLSYSTEM_01_ppbzrf.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1688442470/WinM_Assets/solutions/sol_qwa_2_g2eoag.bmp",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1692695570/WinM_Assets/solutions/acoustic_wall_withlumps_dphycu.bmp",
      },
    ],
    solution_title: "ACOUSTIC CEILING SYSTEMS",
    solution_chars: [
      {
        sol_desc:
          "Features patented ridged vinyl-locking channel that is adjustable to any interior surface and excellent controls over noise resonation.",
      },
      {
        sol_desc:
          "It fits any design configuration and eliminates gaps and over-under fit of prefabricated acoustic panels. At the same time, owing easy repairs or replacements of fabric coverings",
      },
      {
        sol_desc:
          "Ideal for theatres, cinemas, civic centers, and home theaters",
      },
    ],
  },

  {
    solution_id: 15,
    solution_name: "Wallpaper Finish",
    solution_desc:
      "Ideal for wall systems that involve different patterns and design finishes.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912835/WALLPAPERFINISH_01_akpti7.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912835/WALLPAPERFINISH_01_akpti7.jpg",
      },
    ],
    solution_title: "WALL ACCENTS (ARCHITECTURAL INTERIOR WALL)",
    solution_chars: [
      {
        sol_desc:
          "A wide range of architectural accents provides a powerful yet stylish finish for a wall, ceiling, or any space.",
      },
      {
        sol_desc:
          "We go beyond the typical colors, textures, and wallpapers as we believe that accents should further highlight the wall's features and overall presentation.",
      },
      {
        sol_desc:
          "Ideal for wall systems that involve different patterns and design finishes.",
      },
    ],
  },
  {
    solution_id: 16,
    solution_name: "Polyester Acoustic Panel Finish",
    solution_desc:
      "Ideal for wall systems that involve sound absorption finish.",
    solution_img:
      "https://res.cloudinary.com/dgukgozi7/image/upload/v1708679642/LENVOSA_ycgtoe.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708679642/LENVOSA_ycgtoe.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708679895/LENVOSA1_yp5aby.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708679092/WINM_89_w6yj39.jpg",
      },

      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708741851/Polyester_Acoustic_Gobe_Tower_1_dimdnk.jpg",
      },

      {
        sol_img:
          "https://res.cloudinary.com/dgukgozi7/image/upload/v1708741883/Polyester_Acoustic_Gobe_Tower2_dyucl6.jpg",
      },
    ],
    solution_title: "WALL ACCENTS (ARCHITECTURAL INTERIOR WALL)",
    solution_chars: [
      {
        sol_desc:
          "Accent that can help significantly reduce the level of noise lost to the specific area.",
      },
      {
        sol_desc:
          "Offers a variety of colors and shapes that are customizable with the client’s desired design.",
      },
      {
        sol_desc:
          "Ideal for wall systems that involve a sound absorption finish.",
      },
    ],
  },
  {
    solution_id: 17,
    solution_name: "Laminate Finish",
    solution_desc:
      "Ideal for wall systems that involve different patterns and design finishes.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912953/viber_image_2023-08-09_11-33-11-656_hujtlm.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691912953/viber_image_2023-08-09_11-33-11-656_hujtlm.jpg",
      },
    ],
    solution_title: "WALL ACCENTS (ARCHITECTURAL INTERIOR WALL)",
    solution_chars: [
      {
        sol_desc: "Easy to install, low maintenance, and built to last.",
      },
      {
        sol_desc:
          "Customizable and offers a wide selection of designs and patterns.",
      },
      {
        sol_desc:
          "Ideal for wall systems that involve different patterns and design finishes.",
      },
    ],
  },
  {
    solution_id: 18,
    solution_name: "Seating Concept",
    solution_desc: "Ideal for auditoriums and cinemas.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808321/WinM_Assets/solutions/sol_ca_aumxrr.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808321/WinM_Assets/solutions/sol_ca_aumxrr.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808323/WinM_Assets/solutions/sol_ca-1_lqwdcy.png",
      },
    ],
    solution_title: "CINEMA ACCESSORY",
    solution_chars: [
      {
        sol_desc:
          "Offers a wide range of fixed theater solutions built using US-made and US-sourced materials such as seat cloth, cushions, cup holders, snack trays, and functions such as reclining seats or private nested chairs for couples.",
      },

      {
        sol_desc:
          "Understands the dynamics of a good theater that gives a lasting positive impression on visitors and sets the client's space among the rest.",
      },
      {
        sol_desc: "Ideal for auditoriums and cinemas.",
      },
    ],
  },
  {
    solution_id: 19,
    solution_name: "Dinac Wall Guards",
    solution_desc: "Ideal for corridors and lobbies.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1687338130/WinM_Assets/solutions/sol_dwg-1_vtqabl.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1687338130/WinM_Assets/solutions/sol_dwg-1_vtqabl.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1687338131/WinM_Assets/solutions/sol_dwg-3_lgfom0.jpg",
      },
    ],
    solution_title: "Cinema Accessory",
    solution_chars: [
      {
        sol_desc:
          "Protects walls from shock and deterioration commonly found in a building's high-traffic areas.",
      },
      {
        sol_desc:
          "Made up of high-quality aluminum materials that provide wall protection from unnecessary damage.",
      },
      {
        sol_desc: "Ideal for corridors and lobbies.",
      },
    ],
  },
  {
    solution_id: 20,
    solution_name: "Dinac Stair Nosing",
    solution_desc: "Ideal for auditoriums and cinemas.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1687337842/WinM_Assets/solutions/sol_dns-1_qfzg1x.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1687337842/WinM_Assets/solutions/sol_dns-1_qfzg1x.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1687337842/WinM_Assets/solutions/sol_dns-2_vitzl0.jpg",
      },
    ],
    solution_title: "CINEMA ACCESSORY",
    solution_chars: [
      {
        sol_desc:
          "A comprehensive line of lighted profiles that help guide people in dark public spaces such as stairs, aisles, and walls.",
      },
      {
        sol_desc:
          "Customizable lights color depending on the approved client specifications.",
      },
      {
        sol_desc: "Ideal for auditoriums and cinemas.",
      },
    ],
  },
  {
    solution_id: 21,
    solution_name: "Toilet Partition",
    solution_desc:
      "Ideal for comfort rooms of offices, restaurants, shopping centers, and others.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913568/Toilet_Partition_3_nmn6x7.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913568/Toilet_Partition_01_twbqxl.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913576/Toilet_Partition_2_kntmz0.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913568/Toilet_Partition_3_nmn6x7.jpg",
      },
    ],
    solution_title: "PARTITION",
    solution_chars: [
      {
        sol_desc:
          "Installation of durable partitions resistant to impact, water, humidity, and other elements that will last for a period without compromising build stability, design style, and privacy.",
      },
      {
        sol_desc:
          "Ideal for comfort rooms of offices, restaurants, shopping centers, and others.",
      },
    ],
  },
  {
    solution_id: 22,
    solution_name: "Flooring Finishes",
    solution_desc:
      "Ideal for any halls, lobbies, and other interior space construction",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_ff_hwnq8u.png",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_ff_hwnq8u.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808323/WinM_Assets/solutions/sol_ff-1_zh5y2f.png",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_ff-2_vca7dh.png",
      },

      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685808322/WinM_Assets/solutions/sol_ff-3_c1lt48.png",
      },
    ],
    solution_title: "OTHER SERVICES",
    solution_chars: [
      {
        sol_desc:
          "Offers various flooring finishes to accommodate the user's choice of aesthetics while ensuring that the output will provide the most satisfying results based on objective, whether economically or durability-wise",
      },
      {
        sol_desc:
          "Ideal for any halls, lobbies, and other interior space construction",
      },
    ],
  },
  {
    solution_id: 23,
    solution_name: "Blinds & Curtains",
    solution_desc:
      "Ideal for any commercial and residential interior projects.",
    solution_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913833/WinM_Assets/solutions/new%20winm%20102/viber_image_2022-11-23_13-47-14-959_fbkswg.jpg",
    solution_images: [
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913833/WinM_Assets/solutions/new%20winm%20102/viber_image_2022-11-23_13-47-14-959_fbkswg.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913833/WinM_Assets/solutions/new%20winm%20102/viber_image_2022-11-23_13-47-14-055_t2mump.jpg",
      },
      {
        sol_img:
          "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1691913833/WinM_Assets/solutions/new%20winm%20102/viber_image_2023-05-05_18-08-59-642_h0sdh4.jpg",
      },
    ],
    solution_title: "OTHER SERVICES",
    solution_chars: [
      {
        sol_desc:
          "Our space experts will recommend the best blinds or curtains to achieve the ideal and sophisticated window look.",
      },
      {
        sol_desc:
          "Offers a diverse option of blinds and curtains with varying light, wind, and insulation output that can solve various needs and goals for commercial and residential projects. ",
      },
      {
        sol_desc: "Ideal for any commercial and residential projects.",
      },
    ],
  },
];

// Solutions.forEach((solution) => {
//   const solutionImage = SolutionsImages.find(
//     (image) => image.solution_id === solution.solution_id
//   );
//   if (solutionImage) {
//     const publicId = GetPublicId(solutionImage.solution_url);
//     const buildImageUrl = BuildImageURL(publicId);
//     solution.solution_img = buildImageUrl;
//     solution.solution_images.forEach((image) => {
//       image.sol_img = buildImageUrl;
//     });
//   }
// });

const SolutionsContext = createContext({
  activeSolution: null,
});

const SolutionsProvider = ({ children }) => {
  const [solutionState, setSolutionState] = useState({ activeSolution: null });

  const setActiveSolution = (solution) => {
    setSolutionState((state) => ({
      ...state,
      activeSolution: solution,
    }));
  };

  return (
    <SolutionsContext.Provider
      value={{
        ...solutionState,
        setActiveSolution,
      }}
    >
      {children}
    </SolutionsContext.Provider>
  );
};

const useSolution = () => useContext(SolutionsContext);

export { SolutionsProvider, useSolution, Solutions };
