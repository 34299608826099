import { useEffect, useRef } from "react";
// import agent from "../../images/Partners/bapaord_agent.png";
// import distributor from "../../images/Partners/bapaord_distributor.png";
import CheckBullet from "../Bullet/CheckBullet";
import DefaultButton from "../Buttons/DefaultButton";
import { Agent } from "./SVG/Agent";
import { Distributor } from "./SVG/Distributor";

import Fade from "react-reveal/Fade";
const BAPFirst = ({ scrollTo }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (scrollTo) {
      const bapFirstElement = containerRef.current;
      if (bapFirstElement) {
        const offset = 114; // Adjust this value to control the scroll offset in pixels
        const behaviorOptions = {
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        };
        const scrollOptions = {
          ...behaviorOptions,
          top: bapFirstElement.offsetTop - offset,
        };
        window.scrollTo(scrollOptions);
      }
    }
  }, [scrollTo]);

  const scrollToPartnerForm  = () => {
        const form = document.getElementById('partner_form');
        if (form) {
          // form.scrollIntoView({  behavior: "smooth", offsetTop:114 });
          const offset = 114; // Adjust this value as needed
          const rect = form.getBoundingClientRect();
          const scrollTop = window.scrollY;
          const targetScrollTop = rect.top + scrollTop - offset;

          window.scrollTo({
            top: targetScrollTop,
            behavior: "smooth",
          });
      
        }
  }

  return (
    <div
      className="container-fluid px-[120px] flex flex-col py-[90px] mobile:py-[60px] mobile:px-[20px]"
      id="bap-first"
      ref={containerRef}
    >
      <div className="flex flex-col mx-auto gap-[15px]">
        <Fade top delay={200}>
          <span className="text-[12px] leading-[0.2em] text-[#878787] mr-auto uppercase mx-auto mobile:ml-0 mobile:mr-auto">
            how to become a partner
          </span>
        </Fade>

        <Fade delay={400}>
          <div className="text-[42px] leading-[45px] text-center mobile:text-left">
            Partner with us in{" "}
            <span className="text-[#016EB8]">2 Different Ways</span>
          </div>
        </Fade>
      </div>
      <div className="flex flex-row gap-[15px] mt-[60px] tablet:flex-col">
        <Fade left delay={300}>
          <div className="flex flex-col bapaord-left rounded-lg bg-[#F7FAFC] pt-[70px] pb-[50px] px-[60px] mobile:px-[25px]">
            <div className="mx-auto">
              <Agent />
            </div>
            <div className="text-[32px] leading-[35px] tracking-[-0.03em] mx-auto mt-[30px]">
              Sales Agent
            </div>
            <div className="text-[15px] leading-[23px] tracking-[-0.03em] mx-auto text-center justify-center align-center mt-[20px]">
              Anyone who would introduce Walls in Motion, Inc. products and
              services to businesses looking for ways to maximize the use of
              their spaces either for efficiency or revenue generation. Use your
              expertise and knowledge to help your business network and still
              earn a referral commission.
            </div>
            <div className="mx-auto mt-[40px]">
              <DefaultButton color="black" onClick={scrollToPartnerForm}>
                Get Started
              </DefaultButton>
            </div>

            {/* <div className="w-full mt-[40px]">
            <div className="h-[2px] w-full bg-[#DDDDDD]"></div>
          </div>

          <div className="flex flex-col mt-[40px] gap-[15px]">
            <div className="flex flex-row gap-[12px]">
              <div className="">
                <CheckBullet color="blue" />
              </div>
              <div className="text-[13px] leading-[18px] tracking-[-0.03em]">
                Earn your commission for every referral that becomes a client of
                Walls in Motion
              </div>
            </div>
            <div className="flex flex-row gap-[12px]">
              <div className="">
                <CheckBullet color="blue" />
              </div>
              <div className="text-[13px] leading-[18px] tracking-[-0.03em]">
                It's simple and easy to get started: Sign-up, Refer, and Earn
              </div>
            </div>
            <div className="flex flex-row gap-[12px]">
              <div className="">
                <CheckBullet color="blue" />
              </div>
              <div className="text-[13px] leading-[18px] tracking-[-0.03em]">
                You dictate the limits to what you can earn
              </div>
            </div>
          </div> */}
          </div>
        </Fade>
        <Fade right delay={300}>
          <div className="flex flex-col bapaord-right rounded-lg bg-bapaord-right text-white pt-[70px] pb-[50px] px-[60px]  mobile:px-[25px]">
            <div className="mx-auto">
              <Distributor />
            </div>
            <div className="text-[32px] leading-[35px] tracking-[-0.03em] mx-auto mt-[30px]">
              Distributor
            </div>
            <div className="text-[15px] leading-[23px] tracking-[-0.03em] mx-auto text-center justify-center align-center mt-[20px]">
              Anyone who would like to bring Walls in Motion, Inc. products and
              services in selected areas in Visayas and Mindanao to expand their
              offerings to local businesses to maximize the use of their spaces
              either for efficiency or revenue generation.
            </div>
            <div className="mx-auto mt-[40px]">
              <DefaultButton onClick={scrollToPartnerForm}>Get Started</DefaultButton>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default BAPFirst;
