// image generator for static images

import { BuildImageURL, GetPublicId } from "./ImageBuilder";

const ImageGenerator = ({ image, styles }) => {
  const publicId = GetPublicId(image);
  const imageUrl = BuildImageURL(publicId);

  return <img src={imageUrl} className={styles} />;
};

export default ImageGenerator;
