const NavCircleLine = ({ year, isActive }) => {
  return (
    <>
      <svg
        className="mx-auto z-10"
        width="40"
        height="73"
        viewBox="0 0 40 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="nav-slide-circle"
          cx="20"
          cy="53"
          r="17.5"
          fill={isActive ? "#222222" : "#939698"}
          stroke={isActive ? "#016EB8" : "#E3E6E8"}
          strokeWidth="5"
        />
        <path
          className="nav-slide-line"
          d="M20.5 0.226497L14.7265 6L20.5 11.7735L26.2735 6L20.5 0.226497ZM21.5 35L21.5 6L19.5 6L19.5 35L21.5 35Z"
          fill={isActive ? "#016EB8" : "#E3E6E8"}
        />
      </svg>
    </>
  );
};

export default NavCircleLine;
