import DefaultButton from "../Buttons/DefaultButton";
import Fade from "react-reveal/Fade";
const CareersBody = ({}) => {
  return (
    <div className="py-[90px] px-[120px] mobile:px-[20px] mobile:py-[60px]">
      <div className="flex flex-row gap-[50px] tablet:flex-col">
        <Fade left delay={300}>
          <div className="flex-1">
            <img
              src="https://res.cloudinary.com/dgukgozi7/image/upload/v1702864283/Portfolio-Career_qvuc0n.png"
              className="w-full"
            />
          </div>
        </Fade>

        <div className="flex flex-col flex-1 gap-[50px] my-auto">
          <div className="flex flex-col gap-[30px]">
            <Fade top delay={500}>
              <div className="text-[42px] leading-[45px] tracking-[-0.03em] pr-[100px] tablet:pr-0">
                Take your career to the{" "}
                <span className="text-[#016EB8]">next level</span> by joining
                our team at WinM!
              </div>
            </Fade>
            <Fade delay={700}>
              <div className="text-[15px] leading-[23px] tracking-[-0.03em]">
                Walls in Motion, Inc is building a stronger foundation to
                achieve another successful 25 years! We are looking for
                collaborative candidates who love finding win-win solutions to
                challenges. In WinM, Together We Win!
              </div>
            </Fade>
          </div>
          <div className="">
            <Fade bottom delay={500}>
              <DefaultButton color="black" route="careers-form">
                Join Us Today
              </DefaultButton>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersBody;
