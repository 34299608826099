import { Link } from "react-router-dom";

const SolutionSubNav = ({ solutionName }) => {
  return (
    <>
      <div className="w-full pt-[120px] bg-[#2C2C2C]">
        <div className="px-[120px] py-[20px] flex flex-row gap-[5px] text-white  mobile:px-[20px]">
          <span className="text-white text-opacity-[50%]">
            <Link to="/">Home</Link>
          </span>
          <span className="text-white text-opacity-[50%]">/</span>
          <span className="text-white text-opacity-[50%]">
            <Link to="/solutions">Solutions</Link>
          </span>
          <span className="text-white text-opacity-[50%]">/</span>
          <span className="">{solutionName}</span>
        </div>
      </div>
    </>
  );
};

export default SolutionSubNav;
