const PlaySVG = () => {
  return (
    <>
      <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="my-auto mx-auto"
      >
        <path
          d="M0.306641 0.860821C0.306641 0.206828 1.00724 -0.207266 1.58025 0.107506L10.3969 4.95745C10.5317 5.03165 10.6441 5.14065 10.7223 5.27309C10.8006 5.40553 10.8419 5.55655 10.8419 5.71038C10.8419 5.86422 10.8006 6.01524 10.7223 6.14768C10.6441 6.28011 10.5317 6.38912 10.3969 6.46332L1.58025 11.3125C1.44942 11.3844 1.30211 11.421 1.15283 11.4187C1.00355 11.4164 0.857445 11.3752 0.728909 11.2993C0.600372 11.2233 0.493836 11.1152 0.419792 10.9856C0.345748 10.8559 0.306751 10.7092 0.306641 10.5599V0.860821Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default PlaySVG;
