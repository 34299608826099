import CheckBullet from "../Bullet/CheckBullet";
import DefaultButton from "../Buttons/DefaultButton";
import ImageGenerator from "../../utils/ImageGenerator";
import Fade from "react-reveal/Fade";

const BAPSecond = () => {
  const scrollToPartnerForm  = () => {
    const form = document.getElementById('partner_form');
    if (form) {
      // form.scrollIntoView({  behavior: "smooth", offsetTop:114 });
      const offset = 114; // Adjust this value as needed
      const rect = form.getBoundingClientRect();
      const scrollTop = window.scrollY;
      const targetScrollTop = rect.top + scrollTop - offset;

      window.scrollTo({
        top: targetScrollTop,
        behavior: "smooth",
      });
  
    }
}
  return (
    <>
      <div className="py-[90px] bg-[#F7FAFC] mobile:py-[60px]">
        <div className="flex flex-row px-[120px] tablet:flex-col tablet:gap-[60px] mobile:px-[20px]">
          <Fade left delay={100}>
            <div className="flex-1 flex">
              <img
                src="https://res.cloudinary.com/dgukgozi7/image/upload/v1703813501/shutterstock_463364912_cyhodx.jpg"
                styles="w-full h-auto my-auto"
              />
            </div>
          </Fade>

          <div className="flex-1 flex flex-col my-auto gap-[43px] ml-[50px] tablet:ml-0">
            <div className="flex flex-col">
              <Fade top delay={100}>
                <div className="text-[36px] leading-[39px]  text-left">
                  A Partnership that{" "}
                  <span className="text-[#016EB8]">Pays Off</span>
                </div>
              </Fade>
              <Fade delay={300}>
                <div className="text-[15px] leading-[23px] tracking-[-0.03em] mt-[20px]">
                  Walls in Motion, Inc. brings its 25 years of experience to
                  ensure that our partner is backed by proven products and
                  services that will satisfy even the most quality-conscious
                  business owners. A partnership that will prove to be mutually
                  beneficial and will be there for the long term.
                </div>
              </Fade>
            </div>
            <div className="flex flex-col gap-[40px]">
              <Fade left delay={600} distance="5%">
                <div className="flex flex-row gap-[20px]">
                  <div className="flex my-auto">
                    <CheckBullet color="custom" />
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <div className="text-[20px] leading-[23px] tracking-[-0.03em]">
                      Start Right Away!
                    </div>
                    <div className="text-[13px] leading-[18px] tracking-[-0.03em]">
                      A simple accreditation process to get you onboard quickly.
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade left delay={800} distance="5%">
                <div className="flex flex-row gap-[20px]">
                  <div className="flex my-auto">
                    <CheckBullet color="custom" />
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <div className="text-[20px] leading-[23px] tracking-[-0.03em]">
                      Tap your Network with Us
                    </div>
                    <div className="text-[13px] leading-[18px] tracking-[-0.03em]">
                      Be incentivize by referring prospects or potential
                      clients.
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade left delay={1000} distance="5%">
                <div className="flex flex-row gap-[20px]">
                  <div className="flex my-auto">
                    <CheckBullet color="custom" />
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <div className="text-[20px] leading-[23px] tracking-[-0.03em]">
                      We take care of you and your clients!
                    </div>
                    <div className="text-[13px] leading-[18px] tracking-[-0.03em]">
                      Explore the best options and deliver beyond expectations.
                      Be there whenever they need support.
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <Fade bottom delay={1200}>
              <div className="">
                <DefaultButton color="black" onClick={scrollToPartnerForm}>Get Started</DefaultButton>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default BAPSecond;
