export const Distributor = () => {
  return (
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.8512 0.577736L32.2734 1.15547V18.7066V36.2379L32.7516 36.7957L33.2297 37.3535H51H68.7703L69.2484 36.7957L69.7266 36.2379V26.4961V16.7543L69.2684 16.2563C68.591 15.4594 67.7344 15.3996 66.9773 16.0371L66.3398 16.575V25.3207V34.0664H51H35.6602V18.7266V3.38672H39.9434H44.2266V7.96875V12.5707L44.7246 13.0488L45.2027 13.5469H51H56.7973L57.2754 13.0488L57.7734 12.5707V7.96875V3.38672H62.0566H66.3398V7.31133C66.3598 11.4352 66.3996 11.6543 67.2762 12.1125C67.9734 12.491 68.5312 12.3914 69.1488 11.7738L69.7266 11.1961V6.17578V1.15547L69.1488 0.577736L68.5711 0H51H33.4289L32.8512 0.577736ZM54.3867 6.77344V10.1602H51H47.6133V6.77344V3.38672H51H54.3867V6.77344Z"
        fill="white"
      />
      <path
        d="M40.1227 20.5992C39.286 20.9777 38.9075 21.8941 39.2462 22.7109C39.3856 23.0496 39.6645 23.4082 39.8637 23.5078C40.0629 23.6273 42.0352 23.707 44.2864 23.707C48.2708 23.707 48.3504 23.707 48.7688 23.2488C49.3864 22.5914 49.3465 21.5156 48.6891 20.898C48.1911 20.4398 48.0915 20.4199 44.4657 20.3801C41.6567 20.3402 40.5809 20.4 40.1227 20.5992Z"
        fill="white"
      />
      <path
        d="M39.6446 27.6914C38.7481 28.5879 39.0669 30.0422 40.2622 30.4605C41.1985 30.7793 45.6809 30.7394 46.5176 30.4008C47.3544 30.0422 47.693 29.4445 47.5337 28.5679C47.3145 27.4125 46.9161 27.2929 43.2903 27.2929C40.3219 27.2929 40.0032 27.3328 39.6446 27.6914Z"
        fill="white"
      />
      <path
        d="M83.1739 36.6961C82.8352 36.9949 82.6559 37.3734 82.636 37.752C82.636 38.0707 83.4129 40.5809 84.3891 43.3301C86.7 49.8844 86.7399 49.9242 89.7082 48.8285C90.5848 48.4898 91.2422 48.3504 91.1825 48.4699C91.1227 48.6094 90.1266 51.1992 88.9512 54.2473C87.7559 57.2953 86.7399 59.7457 86.6602 59.7258C85.5844 59.2676 76.1414 53.8688 76.2411 53.7891C76.3407 53.7094 76.9782 53.4504 77.6954 53.1914C79.0899 52.7133 79.8868 52.0359 79.8868 51.3387C79.8868 50.6016 76.1215 40.5609 75.743 40.282C74.8266 39.6047 73.6911 39.8637 73.193 40.8996C72.8743 41.5371 72.8942 41.6168 74.4082 45.8004C75.2649 48.1313 75.8625 50.1035 75.7829 50.2031C75.6832 50.2828 74.5079 50.741 73.1532 51.1992C71.5993 51.7371 70.6032 52.2152 70.4039 52.4742C70.0254 53.032 70.0454 54.048 70.4836 54.566C71.0016 55.2035 87.0586 63.9492 87.6961 63.9492C88.7918 63.9492 89.0707 63.4313 92.5172 54.4863C94.37 49.6852 95.8243 45.641 95.8243 45.2625C95.8243 44.5652 95.3063 43.9078 94.5891 43.7285C94.3102 43.6488 93.1348 43.9676 91.561 44.5254C90.1465 45.0234 88.9114 45.3621 88.8317 45.2824C88.752 45.2027 88.0348 43.3301 87.2579 41.1387C85.7239 36.9152 85.3454 36.2578 84.2696 36.2578C83.9508 36.2578 83.4528 36.457 83.1739 36.6961Z"
        fill="white"
      />
      <path
        d="M19.1847 38.011C18.5472 38.7481 17.2324 40.3219 16.2363 41.4973C15.2601 42.6727 14.3836 43.629 14.2839 43.629C14.2043 43.629 13.2082 42.8719 12.0925 41.9356C10.9769 40.9993 9.86129 40.1825 9.6023 40.1028C8.98472 39.9633 8.26754 40.302 7.84918 40.9395C7.55035 41.4176 7.53043 42.1348 7.74957 50.8606C7.96871 59.3672 8.02847 60.3036 8.34722 60.7618C8.54644 61.0407 8.92496 61.3395 9.20386 61.4391C9.56246 61.5586 12.3316 61.2 18.9457 60.184C28.807 58.65 28.8867 58.6301 29.1656 57.4747C29.3847 56.6379 28.9464 56.0602 26.8148 54.2872L24.7031 52.534L25.241 51.9165C29.2453 47.1551 30.4007 45.7407 30.5003 45.3223C30.6796 44.6649 30.1019 43.5692 29.4644 43.35C28.4086 42.9915 28.05 43.2903 24.3246 47.7329C20.9976 51.6774 20.7187 52.0758 20.7187 52.793C20.7187 53.5102 20.8382 53.6696 22.1132 54.7852C22.8902 55.4426 23.4878 55.9805 23.4679 56.0004C23.4281 56.0004 20.7187 56.4188 17.4117 56.9567L11.4152 57.893L11.2957 54.7852C11.216 53.0918 11.1562 50.3227 11.1562 48.6493V45.5813L12.491 46.6969C13.8656 47.8325 14.523 48.0516 15.3398 47.6133C15.8976 47.3145 22.691 39.1266 22.8304 38.5688C22.9699 38.011 22.4918 36.9551 22.0136 36.7758C21.8343 36.7161 21.3761 36.6563 21.0175 36.6563C20.4199 36.6563 20.1808 36.8356 19.1847 38.011Z"
        fill="white"
      />
      <path
        d="M44.7246 44.7246L44.2266 45.2027V49.8047V54.3867H41.5371C38.0508 54.3867 37.4531 54.6457 37.4531 56.1797C37.4531 56.5383 37.6723 57.0363 38.0109 57.4148C40.7402 60.6023 48.6691 69.627 49.3863 70.3641C50.1234 71.1609 50.4023 71.3203 51 71.3203C51.5977 71.3203 51.8766 71.1609 52.6137 70.3641C53.3309 69.627 61.2598 60.6023 63.9891 57.4148C64.3277 57.0363 64.5469 56.5383 64.5469 56.1797C64.5469 54.6457 63.9492 54.3867 60.4629 54.3867H57.7734V49.8047V45.2027L57.2754 44.7246C56.4586 43.8879 55.1836 44.107 54.6258 45.1629C54.307 45.7406 54.2871 56.1598 54.6059 56.8371C54.9445 57.5742 55.5023 57.7734 57.3949 57.8133L59.1082 57.873L55.1637 62.3555C52.9922 64.8258 51.1195 66.8379 51 66.8379C50.8805 66.8379 49.0078 64.8258 46.8363 62.3555L42.8918 57.873L44.6051 57.8133C46.4977 57.7734 47.0555 57.5742 47.3941 56.8371C47.7129 56.1598 47.693 45.7406 47.3742 45.1629C46.8164 44.107 45.5414 43.8879 44.7246 44.7246Z"
        fill="white"
      />
      <path
        d="M0.776953 69.9855C0.0199219 70.5633 0 70.8223 0 81.7793V92.4574L0.498047 92.9355L0.976172 93.4336H11.9133H22.8504L23.2687 92.9754L23.707 92.5172V81.6V70.7027L23.209 70.2246L22.7309 69.7266H11.9133C3.72539 69.7465 1.01602 69.8062 0.776953 69.9855ZM6.77344 76.5797C6.77344 78.4922 6.85313 80.2852 6.97266 80.584C7.33125 81.5402 8.18789 81.7195 12.252 81.6398C15.798 81.5801 15.9176 81.5602 16.4156 81.102L16.9336 80.6238V76.8586V73.1133H18.627H20.3203V81.5801V90.0469H11.8535H3.38672V81.5801V73.1133H5.08008H6.77344V76.5797ZM13.5469 75.7031V78.3129L11.9133 78.2531L10.2598 78.1934L10.2 75.6434L10.1402 73.1133H11.8535H13.5469V75.7031Z"
        fill="white"
      />
      <path
        d="M78.791 73.6113L78.293 74.0895V85.0266V95.9637L78.7512 96.382L79.2094 96.8203H90.1266H101.024L101.502 96.3223L102 95.8441V85.1859C102 77.7949 101.94 74.3684 101.781 74.0496C101.661 73.7707 101.343 73.452 101.064 73.3324C100.745 73.173 97.3184 73.1133 89.9273 73.1133H79.2691L78.791 73.6113ZM85.0664 80.2453V84.0105L85.5844 84.4887C86.0824 84.9668 86.1223 84.9668 90.1664 84.9668C95.5254 84.9668 95.107 85.3453 95.1867 80.2852L95.2465 76.5H96.9398H98.6133V84.9668V93.4336H90.1465H81.6797V84.9668V76.5H83.373H85.0664V80.2453ZM91.8 79.0301L91.7402 81.5801L90.1066 81.6398L88.4531 81.6996V79.0898V76.5H90.1465H91.8598L91.8 79.0301Z"
        fill="white"
      />
      <path
        d="M39.6445 78.6914C39.4254 78.9105 39.2062 79.2891 39.1465 79.5281C39.0867 79.7871 39.0668 84.7277 39.0867 90.5051L39.1465 101.044L39.7043 101.522L40.2621 102H51H61.7379L62.2957 101.522L62.8535 101.044L62.9133 90.5051C62.9332 84.7277 62.9133 79.7871 62.8535 79.5281C62.7937 79.2891 62.5746 78.9105 62.3554 78.6914C61.9769 78.3129 61.6383 78.293 51 78.293C40.3617 78.293 40.023 78.3129 39.6445 78.6914ZM45.8601 85.3453C45.9199 88.9113 45.9398 89.0309 46.398 89.5289L46.8761 90.0469H51H55.1238L55.6019 89.5289C56.0203 89.0906 56.0801 88.8316 56.0801 87.5566C56.0801 86.2816 56.0203 86.0227 55.6019 85.5844C55.2633 85.2258 54.9047 85.0664 54.4066 85.0664C53.6297 85.0664 52.7929 85.7039 52.7929 86.3016C52.7929 86.6203 52.5937 86.6602 51 86.6602H49.207V84.1699V81.6797H54.3867H59.5664V90.1465V98.6133H51H42.4336V90.1465V81.6797H44.107H45.8004L45.8601 85.3453Z"
        fill="white"
      />
    </svg>
  );
};
