import { useState } from "react";
import { BuildImageURL, GetPublicId } from "../../../utils/ImageBuilder";
import DefaultButton from "../../Buttons/DefaultButton";
import InputBox from "../../Input/InputBox";
import SendEmail from "../../../api_functions/SendEmail";

const CareersForm = () => {
  const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState('')
    const [formError, setFormError] = useState(false)
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [position,setPosition] = useState('');
    const [message,setMessage] = useState('');
    const [attachment,setAttachment] = useState(null);
    const [attchKey, setAttachKey] = useState(0)

    const [errors,setErrors] = useState({name:'',email: '', phone: '', position: '', attachment: ''});

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleEmailChange = (e) => {
      setEmail(e.target.value)
    }

    const handlePhoneChange = (e) => {
      setPhone(e.target.value)
    }

    const handlePositionChange = (e) => {
      setPosition(e.target.value)
    }

    const handleMessageChange = (e) => {
      setMessage(e.target.value)
    }

    const handleAttachmentChange = (attch) => {

        if(attch){
          
          const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
          const allowedFileType = "application/pdf";
          if (attch.type !== allowedFileType) {
            setAttachment(null)
            setFieldError('attachment', 'Only PDF file type allowed.')
          }else if (attch.size > maxSizeInBytes) {
            setAttachment(null)
            setFieldError('attachment', 'Max file size allowed is 5MB.')
          }else{
            setAttachment(attch)
            setFieldError((prev) => {return {...prev,attachment:''}})
          }
        }else{
          setAttachment(null)
          setAttachKey((prevKey) => prevKey + 1);
        }
        
    }

    const setFieldError = (fieldName, errorMessage) => {
      setErrors((prevErrors) => {
        const updatedErrors = {...prevErrors};
        updatedErrors[fieldName] =  errorMessage
        return updatedErrors
      });
    };
    const resetFieldErrors = () => {
      setErrors((prevErrors) => {
        const updatedErrors = {};      
        Object.keys(prevErrors).forEach((key) => {
          updatedErrors[key] = '';
        });
      
        return updatedErrors;
      });
    };

    const resetFormFields = () => {
      setName('');
      setEmail('');
      setPhone('');
      setPosition('');
      setMessage('');
      setAttachment(null);
      setAttachKey((prevKey) => prevKey + 1);
    }

    const getErrorsCount = () => {
      const count =  Object.values(errors).filter(error => error !== '').length;
      return count;
    }

    const validateForm = () => {

      var error = false;
    
      if (!name.trim()) {
        setFieldError('name', 'Name is required.')
        error = true;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setFieldError('email', 'A valid email address required e.g. you@example.com');
        error = true;
      }

      const phoneRegex = /^[0-9+]+$/;
      if (!phoneRegex.test(phone.trim())) {
        setFieldError('phone', 'A valid phone number required e.g. +631234567890');
        error = true;
      }

      if(!position.trim()) {
        setFieldError('position', 'Position is required.')
        error = true;
      }

      if(attachment){
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
        if (attachment.size > maxSizeInBytes) {
          setAttachment(null)
          setFieldError('attachment', 'Max file size allowed is 5MB.')
          error = true;
        }

        // Check if the file type is not ".pdf"
        const allowedFileType = "application/pdf";
        if (attachment.type !== allowedFileType) {
          setAttachment(null)
          setFieldError('attachment', 'Only PDF file type allowed.')
          error = true;
        }
      }else{
        setFieldError('attachment', 'CV or Resume required (allowed file PDF, max size 5MB)')
        error = true;
      }
    

      return error
    }



    const handleFormSubmission = async() => {
      setSubmitting(true)
      setFormMessage('');
      setFormError(false)
      resetFieldErrors();
     
      if(validateForm()){
        setFormMessage('Invalid inputs, please correct the errors!')
        setSubmitting(false)
      }else{
         
        const formData = {
          name: name.trim(),
          email:email.trim(),
          phone:phone.trim(),
          position:position.trim(),
          coverletter:message.trim(),
          type:'careers'
        }
        try {
          const result = await SendEmail(attachment,formData);
          if (result.success) {
            setFormMessage('Form has been submitted successfully!');
            resetFormFields()
          } else {
            if(result.type==='error'){
              setFormMessage(result.message);
              setFormError(true)
            }else if(result.type==='errors'){
              setErrors((prev) => {return {...prev,...result.message}});
              setFormMessage('Invalid inputs, please correct the errors!');
            }else{
              setFormMessage('Something went wrong. Try again!')
              setFormError(true)
            }
            
          }
        } catch (error) {
          setFormMessage(error.message);
          setFormError(true)
        }        
        setSubmitting(false)
      }
    }
  const formBg =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685528459/WinM_Assets/forms-bg_z0aud1.png";
  const publicId = GetPublicId(formBg);
  const formBgUrl = BuildImageURL(publicId);
  return (
    <div
      className="form-careers px-[220px] py-[90px] tablet:px-[120px] mobile:px-[20px] mobile:py-[60px] bg-cover"
      style={{ backgroundImage: `url(${formBgUrl})` }}
      id="careers-form"
    >
      <div className="flex flex-col  gap-[40px]">
        <div className="flex flex-col text-white">
          <div className="text-[36px] leading-[39px]  text-center justify-center align-center mobile:text-left">
            Be part of this{" "}
            <span className="text-[#016EB8]">Winning Team!</span>
          </div>
          <div className="text-[15px] leading-[23px] tracking-[-0.03em] mt-[20px] text-center justify-center align-center mobile:text-left">
            Send us your info and our human resource team will get in touch with
            you as soon as possible.
          </div>
          { formMessage ? <div className={`p-5 text-base text-white rounded mt-4 w-full mx-auto ${getErrorsCount() > 0 || formError ? 'bg-red-500' : 'bg-green-500'}`}>{formMessage}</div> : ''  }
        </div>
        <div className="flex flex-col gap-[16px]">
          <div className="flex flex-row  gap-[16px] tablet:flex-col">
            <div className="flex flex-col flex-1 text-white gap-[12px]">
              <div className="t text-white">
                Full Name <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <InputBox
                  type="text"
                  placeholder="Your full name here..."
                  custom={`bg-transparent text-white ${errors.name ? 'border-red-500' : ''}`}
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 text-white gap-[12px]">
              <div className=" text-white ">
                Email Address <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <InputBox
                  type="text"
                  placeholder="Your email address here..."
                  custom={`bg-transparent text-white ${errors.email ? 'border-red-500' : ''}`}
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-[16px] tablet:flex-col">
            <div className="flex flex-col  flex-1 text-white gap-[12px]">
              <div className=" text-white">
                Phone Number <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <InputBox
                  type="text"
                  placeholder="e.g. +631234567890"
                  custom={`bg-transparent text-white ${errors.phone ? 'border-red-500' : ''}`}
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 text-white  gap-[12px]">
              <div className=" text-white">
                Available positions <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <InputBox
                  type="dropdown"
                  data="positions"
                  placeholder=""
                  custom={`bg-transparent text-white ${errors.position ? 'border-red-500' : ''}`}
                  value={position}
                  onChange={handlePositionChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 text-white gap-[12px]">
            <div className=" text-white">
              Cover letter (Optional)
            </div>
            <div className="">
              <InputBox
                type="textarea"
                taHeight="125"
                placeholder="Your cover letter here..."
                custom={`bg-transparent text-white `}
                value={message}
                onChange={handleMessageChange}
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 text-white gap-[12px]">
            <div className=" text-white">
              Upload CV or Resume <span className="text-[#E25454]">*</span>
            </div>
            <div className="">
              <InputBox
                type="upload"
                taHeight="125"
                attachKey={attchKey}
                custom={errors.attachment ? 'border-red-500' : ''}
                onChange={handleAttachmentChange}
                error={errors.attachment ? errors.attachment : ''}
                value={attachment}
              />
            </div>
          </div>
        </div>

        <div className="mx-auto">
        <DefaultButton onClick={handleFormSubmission} customClasses={submitting ? 'pointer-events-none opacity-50' : ''}>{submitting ? 'Submitting...' : 'Submit'}</DefaultButton>

        </div>
      </div>
    </div>
  );
};

export default CareersForm;
