const Close = () => {
  return (
    <svg
      className="my-auto"
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="rotate(180 6 3.125)"
        d="M6.25 6.25C6.03906 6.25 5.85156 6.17969 5.71094 6.03906L1.21094 1.53906C0.90625 1.25781 0.90625 0.765625 1.21094 0.484375C1.49219 0.179688 1.98438 0.179688 2.26562 0.484375L6.25 4.44531L10.2109 0.484375C10.4922 0.179688 10.9844 0.179688 11.2656 0.484375C11.5703 0.765625 11.5703 1.25781 11.2656 1.53906L6.76562 6.03906C6.625 6.17969 6.4375 6.25 6.25 6.25Z"
        fill="black"
      />
    </svg>
  );
};

export default Close;
