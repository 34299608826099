import Fade from "react-reveal/Fade";

const PreviewSlide = ({ slide }) => {
  return (
    <>
      <div className="px-[120px] mobile:px-[20px]">
        <div className="flex flex-row tablet:flex-col tablet:gap-[40px]">
          <Fade left distance="20%" delay={300}>
            <div className="flex-1 relative h-full w-full ">
              <img src={slide.ms_img} className="inline-block h-auto" />
            </div>
          </Fade>

          <div className="flex-1 flex flex-col ml-[80px] my-auto gap-[30px] tablet:ml-0">
            <div className="flex flex-col gap-[10px]">
              <Fade top distance="20%" delay={300}>
                <div className="text-[12px] leading-[15.62px] tracking-[0.2em] text-[#016EB8]">
                  {slide.ms_year}
                </div>
              </Fade>
              <Fade top distance="20%" delay={400}>
                <div className="text-[36px] leading-[39px] tracking-[-0.03em] ">
                  {slide.ms_title}
                </div>
              </Fade>
            </div>
            <Fade delay={500}>
              <div className="text-[15px] leading-[23px] tracking-[-0.03em] pr-[80px] mobile:pr-0">
                {slide.ms_desc}
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewSlide;
