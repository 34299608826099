import HeroAUVideo from "../Hero/About Us/HeroAUVideo";
import Fade from "react-reveal/Fade";
const AUFirst = () => {
  return (
    <div className="flex flex-col px-[120px] py-[90px] gap-[60px] mobile:px-[20px] mobile:py-[60px]">
      <div className="flex flex-col">
        <div className="flex flex-col mx-auto gap-[15px]">
          <Fade top delay={300}>
            <span className="text-[12px] leading-[0.2em] text-[#878787] uppercase text-center mobile:text-left">
              who we are
            </span>
          </Fade>
          <Fade top delay={600}>
            <div className="text-[42px] leading-[45px]  text-center mobile:text-left">
              We are a{" "}
              <span className="text-[#016EB8]">Leading Space Management </span>{" "}
              Solutions Company
            </div>
          </Fade>
          <Fade top delay={300}>
            <div className="">
              <div className="text-[15px] leading-[23px] tracking-[-0.03em] mt-[30px] px-[230px] text-center justify-center laptop:px-[100px] mobile:text-left mobile:px-0">
                <span>
                  Our diverse space solutions have synergized the essential
                  needs of architects, engineers, and interior designers. We
                  assure our clients that we provide the best flexibility,
                  privacy, and improved sound control in every space we work
                  with. And we strive to match the aesthetic, materials, and
                  styles that fit our client's design preferences.
                </span>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="">
        <HeroAUVideo />
      </div>
    </div>
  );
};

export default AUFirst;
