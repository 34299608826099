const Mail = () => {
  return (
    <svg
      className="mailSvg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#F2F2F2" />
      <path
        d="M32.2666 32C31.2119 32 30.3916 32.8594 30.3916 33.875C30.3916 34.5 30.665 35.0469 31.1338 35.3984L39.6104 41.7656C40.0791 42.1172 40.665 42.1172 41.1338 41.7656L49.6104 35.3984C50.0791 35.0469 50.3916 34.5 50.3916 33.875C50.3916 32.8594 49.5322 32 48.5166 32H32.2666ZM30.3916 36.375V44.5C30.3916 45.9062 31.4854 47 32.8916 47H47.8916C49.2588 47 50.3916 45.9062 50.3916 44.5V36.375L41.876 42.7812C40.9775 43.4453 39.7666 43.4453 38.8682 42.7812L30.3916 36.375Z"
        fill="#222222"
      />
    </svg>
  );
};

export default Mail;
