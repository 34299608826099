import { Link } from "react-router-dom";
import ImageGenerator from "../../utils/ImageGenerator";

const Footer = () => {
  const logoUrl =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685525436/WinM_Assets/WinM_Black_tfnuuo.png";
  return (
    <>
      <div className="container-fluid bg-[#F5F5F5]">
        <div className="px-[120px] mobile:px-[20px]">
          <div className="flex flex-row py-[50px] laptop:flex-col laptop:gap-[30px]">
            <div className="flex my-auto">
              <ImageGenerator image={logoUrl} styles="mx-auto" />
            </div>
            <div className="flex flex-row gap-[35px] justify-center ml-auto my-auto text-[#777777] laptop:mx-auto tablet:flex-col tablet:grid tablet:grid-cols-2 tablet:text-center sm-mobile:text-left sm-mobile:mx-0 sm-mobile:gap-[40px]">
              <Link to="/">
                <span className=" text-[15px] leading-[23px]">Home</span>
              </Link>
              <Link to="/solutions">
                <span className=" text-[15px] leading-[23px]">Solutions</span>
              </Link>
              <Link to="/accomplishments">
                {" "}
                <span className=" text-[15px] leading-[23px]">
                  Accomplishments
                </span>
              </Link>
              <Link to="/partnerships">
                <span className=" text-[15px] leading-[23px]">
                  Be A Partner
                </span>
              </Link>
              <Link to="/about-us">
                {" "}
                <span className=" text-[15px] leading-[23px]">About Us</span>
              </Link>
              <Link to="/careers">
                <span className=" text-[15px] leading-[23px]">Careers</span>
              </Link>
              <Link to="/contact-us" state={{ scrollToBody: false }}>
                <span className=" text-[15px] leading-[23px]">Contact Us</span>
              </Link>
            </div>
          </div>
          <div className="border-[1px] border-[#DDDDDD] "></div>
          <div>
            <div className="flex flex-row py-[30px] text-[#777777]">
              <span className="mr-auto">© 2023 Designed by Rook Digital</span>
              <span className="ml-auto">
                Terms & Conditions | Privacy Policy
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
