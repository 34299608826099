import React, { useEffect } from "react";
import Slider from "react-slick";
import { useSolution } from "../../../contexts/SolutionsContext";
import Nav from "../../Nav/Nav";
import SolutionSubNav from "./SolutionSubNav";
import SolutionsShowBody from "./SolutionsShowBody";
import DefaultButton from "../../Buttons/DefaultButton";
import SolutionsYMAL from "./SolutionsYMAL";
import CTA from "../../CTA/CTA";
import Footer from "../../Footer/Footer";
import { Link } from "react-router-dom";

const SolutionShow = () => {
  const { activeSolution, setActiveSolution } = useSolution();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,

    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    if (!activeSolution) {
      // Check if activeSolution is not already set
      const storedSolution = localStorage.getItem("activeSolution");
      if (storedSolution) {
        setActiveSolution(JSON.parse(storedSolution));
      }
    }
  }, []); // Empty dependency array, runs only on initial mount

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (activeSolution) {
      localStorage.setItem("activeSolution", JSON.stringify(activeSolution));
    }
  }, [activeSolution]);

  if (!activeSolution) {
    // Render loading state or redirect to a different page
    return "test";
  }

  return (
    <div className="bg-[#222222]">
      <Nav />
      <SolutionSubNav solutionName={activeSolution.solution_name} />
      {/* Treat this div as a component. Could not make it work when passing the slider to a component, might be a bug within Slick */}
      <div className="container-fluid flex flex-row py-[90px] px-[120px] solutions-current tablet:flex-col  tablet:gap-[60px] mobile:px-[20px] mobile:py-[60px]">
        <div className="w-1/2 flex-1 big-laptop:w-[60%] laptop:w-[65%] tablet:w-full">
          <Slider {...settings}>
            {activeSolution?.solution_images?.map((solution) => (
              <div className="flex" key={solution.sol_img}>
                <img src={solution.sol_img} className="flex-1 w-full" alt="" />
              </div>
            ))}
          </Slider>
        </div>
        <div className="w-1/2 flex-1 flex flex-col text-white pl-[60px] tablet:w-full tablet:pl-0">
          <div className="">
            <button
              className={`bg-[#016EB8] whitespace-nowrap py-[6px] px-[14px] rounded-[3px] hover:cursor-auto`}
            >
              <span className="uppercase text-white text-[15px] font-medium leading-[19.53px] tracking-[-0.03em]">
                {activeSolution.solution_title}
              </span>
            </button>
          </div>
          <div className="mt-[25px] text-[42px] leading-[45px] tracking-[-0.03em]">
            {activeSolution.solution_name}
          </div>
          <ul className="flex flex-col mt-[30px] ">
            {activeSolution.solution_chars?.map((desc, index) => (
              <li className="" key={index}>
                <div className="flex items-start gap-[12px]">
                  <span className="mr-2">&#8226;</span>
                  <div>{desc.sol_desc}</div>
                </div>
              </li>
            ))}
          </ul> 

          <div className="flex flex-row gap-[8px] mt-[45px]">
            <div className="">
              <DefaultButton route="getaquote">Get A Quote</DefaultButton>
            </div>
            <div className="">
              <Link to="tel:+0284243370"><DefaultButton>Call Us Now</DefaultButton></Link>
            </div>
          </div>
        </div>
      </div>
      {/* Treat this div as a component. Could not make it work when passing the slider to a component, might be a bug within Slick */}
      <SolutionsYMAL
        activeSolutionId={activeSolution.solution_id}
        activeSolution={activeSolution}
      />
      <CTA />
      <Footer />
    </div>
  );
};

export default SolutionShow;
