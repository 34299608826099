import DefaultButton from "../Buttons/DefaultButton";
import InputBox from "../Input/InputBox";
import { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";

const BAPThird = ({ scrollTo }) => {
  const [projectValue, setProjectValue] = useState("");
  const [projectResult, setProjectResult] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (scrollTo) {
      const partnersForm = document.getElementById("partners-form");
      if (partnersForm) {
        const offset = 114; // Adjust this value as needed
        const rect = partnersForm.getBoundingClientRect();
        const scrollTop = window.scrollY;
        const targetScrollTop = rect.top + scrollTop - offset;

        window.scrollTo({
          top: targetScrollTop,
          behavior: "smooth",
        });
      }
    }
  }, [scrollTo]);

  const handleCalculate = () => {
    const result = projectValue * 0.025;
    setProjectResult(result);
  };

  const handleReset = () => {
    setProjectValue("");
    setProjectResult("");
    setSelectedOption(""); // Reset the selected option to its initial value
  };

  const handleProjectValueChange = (event) => {
    setProjectValue(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div
      className="flex flex-col gap-[50px] mx-[424px] py-[90px] partners-form big-laptop:mx-[360px] laptop:mx-[260px] tablet:mx-[120px] mobile:mx-[20px]"
      id="partners-form"
    >
      <div className="flex flex-col">
        <Fade top delay={400}>
          <div className="text-[36px] leading-[39px] flex flex-col text-center justify-center align-center mobile:text-left">
            See your earning potential as a{" "}
            <span className="text-[#016EB8]">WinM Certified Partner</span>
          </div>
        </Fade>
      </div>
      <div className="flex flex-col gap-[30px]">
        <Fade delay={600}>
          <div className="flex flex-col gap-[18px]">
            <div className="text-[#222222]">
              Project Type <span className="text-[#E25454]">*</span>
            </div>
            <div className="">
              <div className="relative">
                <select
                  value={selectedOption}
                  onChange={handleSelectChange}
                  defaultValue=""
                  className="border-[1px] text-opacity-50 px-[18px] my-[12px] border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent my-auto appearance-none"
                >
                  <option className="text-opacity-50" value="" disabled>
                    Please select one
                  </option>
                  <option className="text-black" value="option1">
                    Movable Wall & Operable Wall
                  </option>
                  <option className="text-black" value="option2">
                    Accordion Partition
                  </option>
                  <option className="text-black" value="option3">
                    Soundfold Partition
                  </option>
                  <option className="text-black" value="option3">
                    Porta Panel Partition
                  </option>
                  <option className="text-black" value="option3">
                    Aluminum Glass Panel
                  </option>
                  <option className="text-black" value="option3">
                    Wall Accessories
                  </option>
                  <option className="text-black" value="option3">
                    Acoustic Wall System
                  </option>
                  <option className="text-black" value="option3">
                    Acoustic Ceiling
                  </option>
                  <option className="text-black" value="option3">
                    Wall Accents (Architectural Interior Wall)
                  </option>
                  <option className="text-black" value="option3">
                    Architectural Fit Outs
                  </option>
                  <option className="text-black" value="option3">
                    Cinema Accessory & Fit Outs
                  </option>
                  <option className="text-black" value="option3">
                    Toilet Partition
                  </option>
                  <option className="text-black" value="option3">
                    Flooring Finishes
                  </option>
                  <option className="text-black" value="option3">
                    Blinds and Curtains
                  </option>
                </select>
                <div className="text-white absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                  >
                    <path
                      d="M13 2L7.16667 9L1.33333 2"
                      stroke="#FFFFFF"
                      strokeWidth="1.5"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                      className="text-black text-opacity-50"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade delay={700}>
          <div className="flex flex-col gap-[18px]">
            <div className="text-[#222222]">
              Estimated Project Value <span className="text-[#E25454]">*</span>
            </div>
            <div className="">
              <InputBox
                type="number"
                data="subscription"
                placeholder="Average: ₱7,000"
                className="PROJECTVALUE"
                value={projectValue}
                onChange={handleProjectValueChange}
              />
            </div>
          </div>
        </Fade>
        <Fade delay={800}>
          <div className="flex flex-col gap-[18px]">
            <div className="text-[#222222]">
              Potential Commission <span className="text-[#E25454]">*</span>
            </div>
            <div className="">
              <InputBox type="disabled" className value={projectResult} />
            </div>
          </div>
        </Fade>
      </div>
      <div className="flex flex-row gap-[8px] mx-auto mobile:mx-0">
        <Fade left delay={600} distance="30%">
          <div className="">
            <DefaultButton color="gray" onClick={handleReset}>
              Reset
            </DefaultButton>
          </div>
        </Fade>
        <Fade delay={600} distance="30%">
          <div className="">
            <DefaultButton color="black" onClick={handleCalculate}>
              Calculate
            </DefaultButton>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default BAPThird;
