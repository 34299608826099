import Nav from "../components/Nav/Nav";
import Banner from "../components/Banner/Banner";
import CareersBody from "../components/Careers/CareersBody";
import CareersForm from "../components/Forms/Types/CareersForm";
import Forms from "../components/Forms/Forms";
import Footer from "../components/Footer/Footer";
import { useEffect } from "react";
import ScrollToTopButton from "../components/Scroll/ScrollToTopButton";
import { useLocation } from "react-router-dom";
const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  return (
    <>
      <Nav
        navClassName="absolute bg-white bg-opacity-70 backdrop-blur text-black"
        navContainerClassName="my-[25px] mx-[60px]"
      />
      <Banner page="Careers" bannerType="careers-banner" />
      <CareersBody />
      <CareersForm />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Careers;
