const SendEmail = async (attachment, requestData) => {
  const apiUrl = 'https://www.wallsinmotioninc.com//api/sendmail'; 

  try {
      const headers = {
          'Content-Type': 'application/json',
      };

      // Check if attachment is present
      if (attachment) {
        const formData = new FormData();
        formData.append('name',requestData.name)
        formData.append('email',requestData.email)
        formData.append('phone',requestData.phone)
        formData.append('position',requestData.position)
        formData.append('coverletter',requestData.coverletter)
        formData.append('type',requestData.type)
        formData.append('attachment', attachment)
          const response = await fetch(apiUrl, {
              method: 'POST',
              body: formData,
          });
          if (!response.ok) {
              return { success:false, type:response.type, message: response.message };
          }
          const result = await response.json();
          return  result;
      } else {
          // No attachment, proceed with the normal JSON request
          const response = await fetch(apiUrl, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(requestData),
          });

          if (!response.ok) {
              return { success: false, type:response.type, message: response.message };
          }

          const result = await response.json();
          return result;
      }
  } catch (error) {
      return { success: false, type:'error', message: error.message };
      
  }
};

export default SendEmail;