import React, { useRef, useEffect, useState } from "react";
import HeroVideo from "./HeroVideo";
import HeroVideoButton from "./HeroVideoButton";
import DefaultButton from "../../Buttons/DefaultButton";
import Fade from "react-reveal/Fade";

const HeroBanner = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  const handlePlayPause = () => {
    setIsVideoPlaying((prevState) => !prevState);
  };
  return (
    <div className="container-fluid">
      <div className="w-full flex">
        <HeroVideo
          isPlaying={isVideoPlaying}
          onTogglePlayPause={handlePlayPause}
        />

        <div className="absolute flex flex-col w-full h-[100vh] overlay gap-[45px] px-[120px] pb-[80px] mobile:px-[20px] mobile:pb-[40px] mobile:gap-[20px]">
          <div className="flex flex-col max-w-[650px] gap-[25px] mt-auto mobile:pr-[60px]">
            <Fade left delay={100}>
              <span className="font-normal text-[50px] leading-[53px] tracking-[-0.03em] text-white mobile:text-[32px] mobile:leading-[35px] xsm-mobile:text-[28px] xsm-mobile:leading-[32px]">
                We are a leading provider of movable walls & partitions in the
                Philippines.
              </span>
            </Fade>
            <Fade left delay={200}>
              <span className="font-normal text-[15px] leading-[23px] tracking-[-0.03em] text-white mobile:text-[15px] mobile:leading-[23px]">
                With over 25 years of experience, we have innovated
                industry-leading solutions and developed an experienced team
                that enables us to provide the perfect solution for every space.
              </span>
            </Fade>
          </div>
          <div className="flex flex-row sm-mobile:flex-col sm-mobile:gap-[20px]">
            <div className="mr-auto flex flex-row gap-[12px] xsm-mobile:flex-col">
              <Fade delay={400}>
                <DefaultButton route="solutions">I'm A Client</DefaultButton>
              </Fade>
              <Fade delay={500}>
                <DefaultButton route="partner">Apply As Partner</DefaultButton>
              </Fade>
            </div>
            <div className="ml-auto sm-mobile:mr-auto sm-mobile:ml-0">
              <HeroVideoButton
                onTogglePlayPause={handlePlayPause}
                isPlaying={isVideoPlaying}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;

{
  /* <button onClick={handlePlayPause}>Play/Pause</button> */
}
