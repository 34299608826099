import Nav from "../components/Nav/Nav";
import AccomplishmentsBody from "../components/Accomplishments/AccomplishmentsBody";
import Banner from "../components/Banner/Banner";
import { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import ScrollToTopButton from "../components/Scroll/ScrollToTopButton";
const Accomplishments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav
        navClassName="absolute bg-white bg-opacity-70 backdrop-blur text-black"
        navContainerClassName="my-[25px] mx-[60px]"
      />
      <Banner page="Accomplishments" bannerType="acc-banner" />
      <AccomplishmentsBody />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Accomplishments;
