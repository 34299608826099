import { createContext, useState, useContext } from "react";

const ClientContext = createContext({});

// Clients database
const Clients = [
  {
    testimonial:
      "In my experience with your company and service, I really appreciate your cooperation and full support to finish and turn over the project with good quality. Looking for ward to working with you in the future!",
    client_name: "Engr. Michael June Disepeda",
    client_title: "Project Engineer",
    img_url:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685529427/WinM_Assets/client-8finity_w0jlwb.png",
  },
  {
    testimonial:
      "Walls in Motion is a great and competitive company. They can accomodate you with their specialty works like the movable partition installed in our project. Highly recommend this company in terms of the quality of their works.",
    client_name: "Engr. Aaron Parpan",
    client_title: "Sr. Project Engineer",
    img_url:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685529426/WinM_Assets/client-bellevue_ftez3h.png",
  },
  {
    testimonial:
      "Did not encounter any problems when it comes to requesting a quotation, and all items are in good condition. They are very responsive and easy to talk to.",
    client_name: "Ms. Yang Garcia",
    client_title: "Project Head",
    img_url:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685529427/WinM_Assets/client-escalma_slhj4m.png",
  },
  {
    testimonial:
      "Did not encounter any problems when it comes to requesting a quotation, and all items are in good condition. They are very responsive and easy to talk to.",
    client_name: "Ms. Yang Garcia",
    client_title: "Project Head",
    img_url:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685529427/WinM_Assets/client-escalma_slhj4m.png",
  },
];

const useClient = () => useContext(ClientContext);

export { Clients, useClient };
