import DefaultButton from "../Buttons/DefaultButton";
import Fade from "react-reveal/Fade";
import Gallery from "../Gallery/Gallery";
const AUTeam = () => {
  const teamList = [
    {
      team_name: "Christmas Lighting",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800243/WinM_Assets/team/CHRISTMAS_LIGHTING_ectu8k.jpg",
    },
    {
      team_name: "Team Building",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800233/WinM_Assets/team/TEAMBUILDING_gaxw0q.jpg",
    },

    {
      team_name: "Kublahi Chapter 2023",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800114/WinM_Assets/team/KUBLAHI_CHAPTER_2023_f9jtdt.jpg",
    },
    {
      team_name: "Strategic Planning 2023",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800110/WinM_Assets/team/STRATEGIC_PANNING_2023_sdmip9.jpg",
    },
    {
      team_name: "Kublahi Chapter 2022",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800104/WinM_Assets/team/KUBLAHI_CHAPTER_2022_uyon7m.jpg",
    },
    {
      team_name: "Golden Globe Annual Awards for Business Excellence",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800102/WinM_Assets/team/Golden_Globe_Annual_Awards_for_Business_Excellence_gpgp5w.jpg",
    },
    {
      team_name: "2nd Quarter Business Review and Sales Summit",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800101/WinM_Assets/team/2nd_Quarter_Business_Review_and_Sales_Summit_j85hzr.jpg",
    },
    {
      team_name: "1st Quarter Business Review",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800094/WinM_Assets/team/1st_Quarter_Business_Review_2023_zudkkn.jpg",
    },
    {
      team_name: "Golf Fellowship",

      team_img:
        "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1350,w_1800/v1688800094/WinM_Assets/team/GOLF_FELLOWSHIP_borzkn.jpg",
    },
  ];
  return (
    <div className="container-fluid mx-[120px]  py-[90px] flex flex-col mobile:mx-[20px] mobile:py-[60px]">
      <Fade top delay={300}>
        <div className="solutions-header text-[42px] leading-[45px] text-center">
          Our <span className="text-[#016EB8]">Team</span>
        </div>
      </Fade>

      <div className="solutions-gallery flex flex-wrap flex-row tablet:flex-col gap-[16px] mt-[50px]">
        <Gallery galleryData={teamList} />
      </div>
    </div>
  );
};

export default AUTeam;
