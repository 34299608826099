// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
// import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

// import { Link } from "react-router-dom";
import { useState } from "react";
import DefaultButton from "../../Buttons/DefaultButton";
import InputBox from "../../Input/InputBox";
import { GetPublicId, BuildImageURL } from "../../../utils/ImageBuilder";
import { Fade } from "react-reveal";
import SendEmail from "../../../api_functions/SendEmail";

// const fbIcon = <FontAwesomeIcon icon={faFacebookF} />;
// const linkedinIcon = <FontAwesomeIcon icon={faLinkedinIn} />;

const HeroContact = () => {
  const contactLeft =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685525437/WinM_Assets/contact-left_bq9va7.png";
  const publicId = GetPublicId(contactLeft);
  const contactLeftUrl = BuildImageURL(publicId);

  const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState('')
    const [formError, setFormError] = useState(false)
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [company,setCompany] = useState('');
    const [message,setMessage] = useState('');

    const [errors,setErrors] = useState({name:'',email: '', phone: '', company: '', message: ''});

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleEmailChange = (e) => {
      setEmail(e.target.value)
    }

    const handlePhoneChange = (e) => {
      setPhone(e.target.value)
    }

    const handleCompanyChange = (e) => {
      setCompany(e.target.value)
    }

    const handleMessageChange = (e) => {
      setMessage(e.target.value)
    }

    const setFieldError = (fieldName, errorMessage) => {
      setErrors((prevErrors) => {
        const updatedErrors = {...prevErrors};
        updatedErrors[fieldName] =  errorMessage
        return updatedErrors
      });
    };
    const resetFieldErrors = () => {
      setErrors((prevErrors) => {
        const updatedErrors = {};      
        Object.keys(prevErrors).forEach((key) => {
          updatedErrors[key] = '';
        });
      
        return updatedErrors;
      });
    };

    const resetFormFields = () => {
      setName('');
      setEmail('');
      setPhone('');
      setCompany('');
      setMessage('');
    }

    const getErrorsCount = () => {
      const count =  Object.values(errors).filter(error => error !== '').length;
      return count;
    }

    const validateForm = () => {

      var error = false;
    
      if (!name.trim()) {
        setFieldError('name', 'Name is required.')
        error = true;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setFieldError('email', 'A valid email address required e.g. you@example.com');
        error = true;
      }

      const phoneRegex = /^[0-9+]+$/;
      if (!phoneRegex.test(phone.trim())) {
        setFieldError('phone', 'A valid phone number required e.g. +631234567890');
        error = true;
      }

      if(!company.trim()) {
        setFieldError('company', 'Company is required field.')
        error = true;
      }

      if(!message.trim()) {
        setFieldError('message', 'Message is required.')
        error = true;
      }
      return error
    }



    const handleFormSubmission = async() => {
      setSubmitting(true)
      setFormMessage('');
      setFormError(false)
      resetFieldErrors();
     
      if(validateForm()){
        setFormMessage('Invalid inputs, please correct the errors!')
        setSubmitting(false)
      }else{
        
        const formData = {
          name: name.trim(),
          email:email.trim(),
          phone:phone.trim(),
          company:company.trim(),
          message:message.trim(),
          type:'home_contact'
        }
        try {
          const result = await SendEmail(false,formData);
          if (result.success) {
            setFormMessage('Form has been submitted successfully!');
            resetFormFields()
          } else {
            if(result.type==='error'){
              setFormError(true)
              setFormMessage(result.message);
            }else if(result.type==='errors'){
              setErrors(result.message);
              setFormMessage('Invalid inputs, please correct the errors!');
            }else{
              setFormError(true)
              setFormMessage('Something went wrong. Try again!');
            }
            
          }
        } catch (error) {
          setFormMessage(error.message);
          setFormError(true)
        }
        
        setSubmitting(false)
      }
    }

  return (
    <>
      <div className="flex flex-row tablet:flex-col">
        <div
          className="hero-contact-l flex-1 flex flex-col py-[90px] pl-[120px] gap-[50px] mobile:px-[20px] "
          style={{ backgroundImage: `url(${contactLeftUrl})` }}
        >
          <div className="flex flex-col pr-[240px] mobile:pr-[0px]">
            <Fade left delay={1000}>
              <span className="text-[12px] leading-[0.2em] text-white uppercase text-left">
                Contact us
              </span>
            </Fade>
            <Fade left delay={1100}>
              <div className="text-[42px] leading-[45px] tracking-[-0.03em] text-white text-left flex flex-col mt-[15px] mobile:text-[26px] mobile:leading-[29px]">
                How can we help?{" "}
                <span className="text-[#4FB8FF]">Send us a message.</span>
              </div>
            </Fade>
            <Fade left delay={1200}>
              <span className="text-[15px] leading-[23px] text-white text-left mt-[28px]">
                Our team of experts is always ready to assist you with
                customized solutions that cater to your specific needs.
              </span>
            </Fade>
          </div>

          <div className="flex flex-col pr-[90px] gap-[20px] mobile:pr-[0px]">
            <Fade left delay={1300}>
              <div className="flex flex-col tracking-[-0.03em] gap-[5px]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Sales & Marketing
                </div>
                <div className="text-white text-[18px] leading-[21px]">
                  sales_inquiry@wallsinmotion.com
                </div>
              </div>
            </Fade>
            <Fade left delay={1400}>
              <div className="flex flex-col tracking-[-0.03em] gap-[5px]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Human Resource
                </div>
                <div className="text-white text-[18px] leading-[21px]">
                  hr@wallsinmotion.com
                </div>
              </div>
            </Fade>
            <Fade left delay={1500}>
              <div className="flex flex-col tracking-[-0.03em] gap-[5px]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Address
                </div>
                <div className="text-white text-[18px] leading-[21px]">
                  No. 36 Eulogio Amang Rodriguez, Brgy. Rosario, Pasig City 1609
                  Metro Manila
                </div>
              </div>
            </Fade>
            <Fade left delay={1600}>
              <div className="flex flex-col tracking-[-0.03em] gap-[5px]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Contact Numbers
                </div>
                <div className="flex flex-row gap-[10px] big-laptop:grid big-laptop:grid-cols-1 mobile:flex-col mobile:gap-[5px]">
                  <span className="text-white text-[18px] leading-[21px] ">
                    02 8424 3370 to 9
                  </span>
                  <span className="text-white text-opacity-40  text-[18px] big-laptop:hidden mobile:hidden">
                    |
                  </span>
                  <span className="text-white text-[18px] leading-[21px] ">
                    +63 917 629 8847
                  </span>
                  
                
{/*                   <span className="text-white text-[18px] leading-[21px] ">
                    +63 956 856 9978
                  </span>
                  <span className="text-white text-opacity-40  text-[18px] big-laptop:hidden mobile:hidden">
                    |
                  </span>
                  <span className="text-white text-[18px] leading-[21px] ">
                    +63 921 420 2213
                  </span> */}
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="hero-contact-r flex-1 p-[90px] mobile:py-[60px] mobile:px-[20px]">
          <div className="container-fluid flex flex-col gap-[20px]">
            { formMessage ? <div className={`p-5 text-base text-white rounded mt-4 w-full mx-auto ${getErrorsCount() > 0 || formError ? 'bg-red-500' : 'bg-green-500'}`}>{formMessage}</div> : ''  }
            <Fade delay={1300}>
              <div className="flex flex-col gap-[15px]">
                <div className="flex flex-row gap-[5px]">
                  <span className="text-[#787878]">Company Name</span>
                  <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <InputBox 
                    placeholder="Your company here..." 
                    value={company}
                    onChange={handleCompanyChange}
                    custom={`${errors.company ? 'border-red-500' : ''}`}
                  />
                  {errors.company ? <div className="text-sm text-red-500 mt-1">{errors.company}</div> : ''}
                </div>
              </div>
              <div className="flex flex-col gap-[15px]">
                <div className="flex flex-row gap-[5px]">
                  <span className="text-[#787878]">Full Name</span>
                  <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <InputBox 
                    placeholder="Your full name here..." 
                    value={name}
                    onChange={handleNameChange}
                    custom={`${errors.name ? 'border-red-500' : ''}`}
                  />
                  {errors.name ? <div className="text-sm text-red-500 mt-1">{errors.name}</div> : ''}
                </div>
              </div>
              <div className="flex flex-row gap-[8px] mobile:flex-col">
                <div className="flex flex-col gap-[15px] flex-1 ">
                  <div className="flex flex-row gap-[5px]">
                    <span className="text-[#787878]">Phone Number</span>
                    <span className="text-[#E25454]">*</span>
                  </div>
                  <div className="w-full">
                    <InputBox 
                      placeholder="e.g. +631234567890" 
                      value={phone}
                      onChange={handlePhoneChange}
                      custom={`${errors.phone ? 'border-red-500' : ''}`}
                    />
                    {errors.phone ? <div className="text-sm text-red-500 mt-1">{errors.phone}</div> : ''}
                  </div>
                </div>
                <div className="flex flex-col gap-[15px] flex-1 ">
                  <div className="flex flex-row gap-[5px] ">
                    <span className="text-[#787878]">Email Address</span>
                    <span className="text-[#E25454]">*</span>
                  </div>
                  <div className="w-full">
                    <InputBox 
                      placeholder="Your email address..." 
                      value={email}
                      onChange={handleEmailChange}
                      custom={`${errors.email ? 'border-red-500' : ''}`}
                    />
                    {errors.email ? <div className="text-sm text-red-500 mt-1">{errors.email}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[15px]">
                <div className="flex flex-row gap-[5px]">
                  <span className="text-[#787878]">Message</span>
                  <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <textarea
                    type="text"
                    placeholder="Your message here..."
                    value={message}
                    onChange={handleMessageChange}
                    className={`px-[18px] py-[12px] border-[1px] border-solid w-full rounded h-[120px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] ${errors.message ? 'border-red-500' : ''}`}
                  />
                  {errors.message ? <div className="text-sm text-red-500 mt-1">{errors.message}</div> : ''}
                </div>
              </div>
            </Fade>
            <Fade bottom delay={1400}>
              <div className="flex mr-auto">
              <DefaultButton color="black" onClick={handleFormSubmission} customClasses={submitting ? 'pointer-events-none opacity-50' : ''}>{submitting ? 'Submitting...' : 'Submit'}</DefaultButton>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid flex flex-row py-[90px]">
        <div className="contact-left flex-1">
          <div className="container-fluid flex flex-col ">
            <div className="flex flex-col gap-[20px]">
              <span className="text-[12px] leading-[0.2em] text-white uppercase text-left">
                Contact us
              </span>
              <div className="text-[42px] leading-[45px] text-white text-left flex flex-col">
                How can we help?{" "}
                <span className="text-[#4FB8FF]">Send us a message.</span>
              </div>
              <span className="text-[15px] leading-[23px] text-white text-left">
                Our team of experts is always ready to assist you with
                customized solutions that cater to your specific needs.
              </span>
            </div>
            <div className="flex flex-col gap-[20px] ">
              <div className="flex flex-col tracking-[-0.03em]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Marketing
                </div>
                <div className="text-white text-[18px] leading-[21px]">
                  hr@wallsinmotion.com
                </div>
              </div>
              <div className="flex flex-col tracking-[-0.03em]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Human Resource
                </div>
                <div className="text-white text-[18px] leading-[21px]">
                  hr@wallsinmotion.com
                </div>
              </div>
              <div className="flex flex-col tracking-[-0.03em]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Address
                </div>
                <div className="text-white text-[18px] leading-[21px]">
                  No. 36 Eulogio Amang Rodriguez, Brgy. Rosario, Pasig City 1609
                  Metro Manila
                </div>
              </div>
              <div className="flex flex-col tracking-[-0.03em]">
                <div className="text-[#4FB8FF] text-[18px] leading-[21px]">
                  Contact Numbers
                </div>
                <div className="text-white text-[18px] leading-[21px] flex flex-row gap-[10px]">
                  <span className="">02 8424 3370 to 9</span>
                  <span className="text-white text-opacity-40">|</span>
                  <span className="">02 8640 7065</span>
                  <span className="text-white text-opacity-40">|</span>
                  <span className="">02 8640 3212</span>
                  <span className="text-white text-opacity-40">|</span>
                  <span className="">02 8641 7895</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-right flex flex-col flex-1 gap-[20px]">
          <div className="container-fluid flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[15px]">
              <div className="flex flex-row gap-[5px]">
                <span className="text-[#787878]">Company Name</span>
                <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <InputBox placeholder="Your company here..." />
              </div>
            </div>
            <div className="flex flex-col gap-[15px]">
              <div className="flex flex-row gap-[5px]">
                <span className="text-[#787878]">Full Name</span>
                <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <InputBox placeholder="Your full name here..." />
              </div>
            </div>
            <div className="flex flex-row gap-[8px]">
              <div className="flex flex-col gap-[15px] flex-1 ">
                <div className="flex flex-row gap-[5px]">
                  <span className="text-[#787878]">Phone Number</span>
                  <span className="text-[#E25454]">*</span>
                </div>
                <div className="w-full">
                  <InputBox placeholder="e.g. 09991727971" />
                </div>
              </div>
              <div className="flex flex-col gap-[15px] flex-1 ">
                <div className="flex flex-row gap-[5px] ">
                  <span className="text-[#787878]">Email Address</span>
                  <span className="text-[#E25454]">*</span>
                </div>
                <div className="w-full">
                  <InputBox placeholder="Your email address..." />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[15px]">
              <div className="flex flex-row gap-[5px]">
                <span className="text-[#787878]">Message</span>
                <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <textarea
                  type="text"
                  placeholder="Your message here..."
                  className="px-[18px] py-[12px] border-[1px] border-solid w-full rounded h-[120px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8]"
                />
              </div>
            </div>
            <div className="flex mr-auto">
              <DefaultButton color="black">Submit Message</DefaultButton>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HeroContact;
