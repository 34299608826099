import React, { useRef, useEffect } from "react";
import HeroBannerVideo from "../../../videos/hero_banner.mp4";

const HeroVideo = ({ isPlaying, onTogglePlayPause }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isPlaying) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.disablePictureInPicture = true;
    return () => {
      videoElement.disablePictureInPicture = false;
    };
  }, []);

  return (
    <>
      <div className="video-container w-full h-[100vh]">
        <video
          ref={videoRef}
          src={HeroBannerVideo}
          className="w-[100vw] h-[100vh] object-cover"
          muted={true}
        />
      </div>
    </>
  );
};

export default HeroVideo;
