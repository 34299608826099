import { useState } from "react";
import Fade from "react-reveal/Fade";
const classNames = require("classnames");

const AccomplishmentsNav = ({ data, selectedSector, onSectorChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSectorSelection = (sectorName) => {
    onSectorChange(sectorName);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div className="flex flex-col gap-[25px] mobile:hidden">
        {data.map((sector) => (
          <Fade cascade delay={200}>
            <button
              key={sector.sector_name}
              onClick={() => onSectorChange(sector.sector_name)}
              className={classNames("text-left", {
                active: selectedSector === sector.sector_name,
              })}
            >
              <span
                className={classNames(
                  "text-left text-[18px] leading-[23.44px] tracking-[-0.03em] hover:underline hover:text-white",
                  {
                    "text-white": selectedSector === sector.sector_name,
                    "text-white/60": selectedSector !== sector.sector_name,
                    underline: selectedSector === sector.sector_name,
                  }
                )}
              >
                {sector.sector_name}
              </span>
            </button>
          </Fade>
        ))}
      </div>

      <div className="hidden mobile:flex mobile:z-[100]">
        <div className="relative w-full">
          <button
            onClick={toggleDropdown}
            className="text-left w-full p-[20px] border-solid border-[1px] rounded border-white flex flex-row"
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
          >
            <div className="">{selectedSector}</div>
            <div className="my-auto ml-auto">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.6665 2L7.83317 9L1.99984 2"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </button>

          {isDropdownOpen && (
            <div className=" mt-2 py-2 w-40 bg-white rounded-md shadow-lg z-[99] w-full">
              {data.map((sector) => (
                <button
                  key={sector.sector_name}
                  onClick={() => handleSectorSelection(sector.sector_name)}
                  className={classNames(
                    "block w-full text-left text-black py-[15px] pl-[25px] z-[99]",
                    {
                      active: selectedSector === sector.sector_name,
                    }
                  )}
                >
                  {sector.sector_name}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AccomplishmentsNav;
