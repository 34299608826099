import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import DefaultButton from "../Buttons/DefaultButton";
import Mobile from "./SVG/Mobile";
import Close from "./SVG/Close";
import Open from "./SVG/Open";

import ImageGenerator from "../../utils/ImageGenerator";
import MobileNav from "./MobileNav";

const Nav = () => {
  const location = useLocation();
  const logoBaseUrl =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685366934/WinM_Assets/WinM_Black_jgulgr.png";
  const fbIcon = <FontAwesomeIcon icon={faFacebookF} />;
  const linkedinIcon = <FontAwesomeIcon icon={faLinkedinIn} />;

  const [showCompany, setShowCompany] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  useEffect(() => {
    if (showMobileNav) {
      document.body.style.overflow = "hidden"; // Hide overflow to prevent scrolling
    } else {
      document.body.style.overflow = ""; // Restore default overflow to allow scrolling
    }
  }, [showMobileNav]);

  const handleMobileNavClick = () => {
    setShowMobileNav(!showMobileNav);
  };

  const handleDownloadPoster = () => {

    const fileName = 'Company_Profile_2023.pdf';

    // Construct the URL for the file in the public folder
    const fileUrl = `${process.env.PUBLIC_URL}/downloadable_files/${fileName}`;
    console.log(fileUrl)

      // Create a temporary link element and trigger a click event
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

  }

  return (
    <nav
      className={
        showMobileNav
          ? `winm-nav fixed top-0 left-0 right-0  w-full z-50  bg-white backdrop-blur text-black z-[9999]`
          : `winm-nav fixed top-0 left-0 right-0  w-full z-50  bg-white backdrop-blur text-black bg-opacity-70 z-[9999]`
      }
    >
      <div
        className={`flex flex-row py-[25px] px-[60px] mobile:px-[20px] gap-[20px]`}
      >
        <div className="flex w-full items-center justify-between h-16">
          <Link to="/" className="">
            <ImageGenerator image={logoBaseUrl} alt="Logo" />
          </Link>
          <div className="block laptop:hidden">
            <div className="ml-10 flex items-baseline  gap-[32px]">
              <Link
                className="font-normal text-base leading-6 tracking-tight capitalize hover:text-[#016EB8]"
                to="/solutions"
              >
                Solutions
              </Link>
              <Link
                to="/accomplishments"
                className="font-normal text-base leading-6 tracking-tight capitalize hover:text-[#016EB8]"
              >
                Accomplishments
              </Link>
              <Link
                to="/partnerships"
                className="font-normal text-base leading-6 tracking-tight capitalize hover:text-[#016EB8]"
                state={{ scrollToBody: false }}
              >
                Be A Partner
              </Link>
              <div className="dropdown">
                <button
                  className="dropdown-button font-normal text-base leading-6 tracking-tight capitalize hover:text-[#016EB8]"
                  onClick={() => setShowCompany(!showCompany)}
                  state={{ scrollToBody: false }}
                >
                  <div className="flex flex-row gap-[7px] ">
                    <span>Company</span>
                    {showCompany ? <Close /> : <Open />}
                  </div>
                </button>
                {showCompany && (
                  <ul className="dropdown-menu absolute bg-white/70 mx-auto mt-[45px] ml-[-32px] flex flex-col rounded-br-lg rounded-bl-lg">
                    <li className="flex">
                      <Link
                        to="/about-us"
                        className="py-[13px] pl-[32px] hover:bg-[#016EB8] hover:text-white w-full h-full"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="flex">
                      <Link
                        to="/careers"
                        className="rounded-br-lg h-full rounded-bl-lg py-[13px] pl-[32px] pr-[130px] hover:bg-[#016EB8] hover:text-white"
                      >
                        Careers
                      </Link>
                    </li>
                  </ul>
                )}
              </div>

              <Link
                to="/contact-us"
                className="dropdown  hover:text-[#016EB8]"
                state={{ scrollToBody: false }}
              >
                Contact Us
              </Link>
              <Link to="/">
                <DefaultButton color="black" onClick={handleDownloadPoster}>Download Poster</DefaultButton>
              </Link>
              <Link to="https://web.facebook.com/wallsinmotionincph/" target="_blank" className="my-auto hover:text-[#016EB8]">
                {fbIcon}
              </Link>
              <Link to="https://www.linkedin.com/company/wallsinmotioninc/" target="_blank" className="my-auto hover:text-[#016EB8]">
                {linkedinIcon}
              </Link>
            </div>
          </div>
        </div>
        <div className="my-auto flex hidden laptop:block">
          {showMobileNav ? (
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mobileSvg"
              onClick={handleMobileNavClick}
            >
              <path
                d="M1 13L13 1M1 1L13 13"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="19"
              height="9"
              viewBox="0 0 19 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mobileSvg"
              onClick={handleMobileNavClick}
            >
              <path
                d="M1 1H17.5M1 7.75H17.5"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>

      {showMobileNav && <MobileNav />}
    </nav>
  );
};

export default Nav;
