import { useState } from "react";
import DefaultButton from "../../Buttons/DefaultButton";

const ProjectsModal = ({ project, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % project.images.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? project.images.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-[99%] z-[111111] py-[40px]">
      <div className="flex flex-col gap-[30px] tablet:px-[40px] acc-modal-custom:w-[65%]">
        <div className=" rounded-md max-w-3xl max-h-3xl flex flex-col ">
          <button
            className="text-white ml-auto mr-[-40px] transform bg-transparent rounded-md tablet:mr-[0px] mb-[20px] "
            onClick={onClose}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L13 1M1 1L13 13"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div className="flex items-center justify-center w-full">
            <img
              src={project.images[currentSlide].url}
              alt={project.images[currentSlide].name}
              className=" laptop-h:h-auto mobile:h-auto"
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-transparent bg-opacity-50 text-white p-4 w-full">
              <div className="px-[60px] flex flex-row w-full">
                <button
                  className="hidden mr-auto tablet:flex"
                  onClick={handlePrevSlide}
                >
                  <svg
                    width="40"
                    height="19"
                    viewBox="0 0 40 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 2L2 9.5M2 9.5L9.5 17M2 9.5H38"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  className="hidden tablet:flex"
                  onClick={handleNextSlide}
                >
                  <svg
                    width="40"
                    height="19"
                    viewBox="0 0 40 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.5 2L38 9.5M38 9.5L30.5 17M38 9.5H2"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="text-white text-[20px] tracking-[-0.03em] leading-[23px] my-auto">
            {project.images[currentSlide].sol}
          </div>
          <div className="ml-auto">
            <DefaultButton route="getaquote">Get A Quote</DefaultButton>
          </div>
        </div>
      </div>
      {project.images.length === 1 ? (
        <>
          <button
            className="hidden top-1/2 left-[230px] transform -translate-y-1/2 px-4 py-2 bg-transparent rounded-md laptop:left-[90px] tablet:hidden"
            onClick={handlePrevSlide}
          >
            <svg
              width="40"
              height="19"
              viewBox="0 0 40 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 2L2 9.5M2 9.5L9.5 17M2 9.5H38"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            className="hidden top-1/2 right-[230px] transform -translate-y-1/2 px-4 py-2 bg-transparent rounded-md laptop:right-[90px] tablet:hidden"
            onClick={handleNextSlide}
          >
            <svg
              width="40"
              height="19"
              viewBox="0 0 40 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.5 2L38 9.5M38 9.5L30.5 17M38 9.5H2"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </>
      ) : (
        <>
          <button
            className="absolute top-1/2 left-[230px] transform -translate-y-1/2 px-4 py-2 bg-transparent rounded-md laptop:left-[90px] tablet:hidden"
            onClick={handlePrevSlide}
          >
            <svg
              width="40"
              height="19"
              viewBox="0 0 40 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 2L2 9.5M2 9.5L9.5 17M2 9.5H38"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            className="absolute top-1/2 right-[230px] transform -translate-y-1/2 px-4 py-2 bg-transparent rounded-md laptop:right-[90px] tablet:hidden"
            onClick={handleNextSlide}
          >
            <svg
              width="40"
              height="19"
              viewBox="0 0 40 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.5 2L38 9.5M38 9.5L30.5 17M38 9.5H2"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </>
      )}
    </div>
  );
};

export default ProjectsModal;
