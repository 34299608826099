import { BuildImageURL, GetPublicId } from "../../utils/ImageBuilder";
import DefaultButton from "../Buttons/DefaultButton";
import Fade from "react-reveal/Fade";

const AUCTA = () => {
  const imageUrl =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685525437/WinM_Assets/generic_cta_ha8e0o.png";
  const publicId = GetPublicId(imageUrl);
  const finalImageUrl = BuildImageURL(publicId);
  return (
    <div
      className="cta py-[90px] px-[120px] tablet:py-[60px] tablet:px-[40px]"
      style={{ backgroundImage: `url(${finalImageUrl})` }}
    >
      <div className="flex flex-row gap-[130px] tablet:flex-col tablet:gap-[40px]">
        <div className="leading-[45px] tracking-[-0.03em] tablet:text-center">
          <Fade left delay={400} distance="20%">
            <span className="text-[42px] text-white leading-[45px] tracking-[-0.03em] ">
              At Walls in Motion, we know that in order to change the world,{" "}
              <span className="text-[#4FB8FF] leading-[45px] tracking-[-0.03em]">
                We just have to keep moving!{" "}
              </span>
            </span>
          </Fade>
        </div>
        <Fade right delay={400} distance="20%">
          <div className="flex flex-row gap-[12px] my-auto tablet:mx-auto">
            <DefaultButton route="solutions">I'm a Client</DefaultButton>
            <DefaultButton route="partner">Apply as Partner</DefaultButton>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default AUCTA;
