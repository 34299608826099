const SmallLocation = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44359 23.9026L9.44782 23.904C9.72591 24.0254 9.88118 23.9972 9.88118 23.9972C9.88118 23.9972 10.0365 24.0254 10.316 23.904L10.3188 23.9026L10.3272 23.8983L10.3527 23.8871C10.4867 23.8248 10.6189 23.7589 10.7493 23.6894C11.0119 23.5539 11.3789 23.3506 11.8179 23.0782C12.6931 22.5362 13.8577 21.7146 15.0279 20.5867C17.3655 18.3338 19.7624 14.8119 19.7624 9.88118C19.7624 8.58357 19.5068 7.29866 19.0102 6.09982C18.5136 4.90098 17.7858 3.81168 16.8682 2.89413C15.9507 1.97658 14.8614 1.24874 13.6625 0.75216C12.4637 0.255584 11.1788 0 9.88118 0C8.58357 0 7.29866 0.255584 6.09982 0.75216C4.90098 1.24874 3.81168 1.97658 2.89413 2.89413C1.97658 3.81168 1.24874 4.90098 0.75216 6.09982C0.255584 7.29866 -1.9336e-08 8.58357 0 9.88118C0 14.8105 2.39689 18.3338 4.73591 20.5867C5.71521 21.5273 6.79063 22.3624 7.94447 23.0782C8.39137 23.3557 8.85112 23.612 9.32219 23.8461L9.40971 23.8871L9.43512 23.8983L9.44359 23.9026ZM9.88118 13.0573C10.7235 13.0573 11.5314 12.7227 12.127 12.127C12.7227 11.5314 13.0573 10.7235 13.0573 9.88118C13.0573 9.03883 12.7227 8.23098 12.127 7.63534C11.5314 7.03971 10.7235 6.70509 9.88118 6.70509C9.03883 6.70509 8.23098 7.03971 7.63534 7.63534C7.03971 8.23098 6.70509 9.03883 6.70509 9.88118C6.70509 10.7235 7.03971 11.5314 7.63534 12.127C8.23098 12.7227 9.03883 13.0573 9.88118 13.0573Z"
        fill="#016EB8"
      />
    </svg>
  );
};

export default SmallLocation;
