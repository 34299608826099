import React from "react";
import Slider from "react-slick";

import ClientSlide from "./ClientSlide";
import ClientSlider from "./ClientSlider";
import Fade from "react-reveal/Fade";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import { Clients } from "../../../contexts/ClientContext";
const HeroClient = () => {
  return (
    <>
      <div className="bg-white flex flex-col gap-[60px] px-[120px] py-[90px] mobile:px-[20px] mobile:gap-[40px] mobile:py-[60px]">
        <div className="client-top flex flex-col gap-[15px] mx-auto">
          <div className="client-title mx-auto">
            <Fade top delay={600}>
              <span className="text-[12px] leading-[0.2em] text-[#878787] uppercase ">
                Our Clients
              </span>
            </Fade>
          </div>
          <div className="client-header text-center justify-center items-center">
            <Fade top delay={800}>
              <span className="leading-[45px] text-[42px] mobile:text-[26px] mobile:leading-[29px]">
                Trusted by{" "}
                <span className="text-[#016EB8]">
                  <CountUp
                    end={1000}
                    redraw={true}
                    enableScrollSpy={true}
                    scrollSpyOnce={true}
                  />
                  + Brands
                </span>{" "}
                Globally
              </span>
            </Fade>
          </div>
        </div>

        <div className="hero-client-slider">
          <Fade delay={1000}>
            <ClientSlider sliderData={Clients} />
          </Fade>
        </div>
      </div>
    </>
  );
};

export default HeroClient;
