import AccomplishmentsGallery from "./AccomplishmentsGallery";
import AccomplishmentsNav from "./AccomplishmentsNav";
import { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";

import { Accomplishments } from "../../contexts/AccomplishmentsContext";

const AccomplishmentsBody = () => {
  const [selectedSector, setSelectedSector] = useState(
    Accomplishments[0].sector_name
  );

  const handleSectorChange = (sectorName) => {
    setSelectedSector(sectorName);
  };
  return (
    <>
      <div className="flex mobile:flex-col">
        <div className="flex flex-col p-[90px] gap-[30px] text-left bg-gradient-to-b from-[#002E4D] to-[#002E4DCC] bg-opacity-80 backdrop-filter backdrop-blur-xl text-white mobile:px-[20px] mobile:py-[40px]">
          <Fade top delay={100}>
            <div className="text-[32px] leading-[35px] tracking-[-0.03em] text-white">
              Sectors We Have{" "}
              <span className="text-[#4FB8FF]">Worked With</span>
            </div>
          </Fade>
          <Fade delay={200}>
            <div className="">
              <div className="border-[1px] border-solid border-[#295675]" />
            </div>
          </Fade>
          <AccomplishmentsNav
            data={Accomplishments}
            selectedSector={selectedSector}
            onSectorChange={handleSectorChange}
          />
        </div>
        <div className="flex">
          <AccomplishmentsGallery selectedSector={selectedSector} />
        </div>
      </div>
    </>
  );
};

export default AccomplishmentsBody;
