import React, { useRef, useEffect } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { Icon, Style } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import LocationVector from "../../images/ContactUs/map-location.png";

const MapComponent = ({ site }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    let centerCoordinates;

    if (site === "pasig") {
      centerCoordinates = fromLonLat([121.08649267032392, 14.59196122633139]);
    } else if (site === "cebu") {
      // centerCoordinates = fromLonLat([123.93336764988388, 10.34287703554258]);
       centerCoordinates = fromLonLat([123.9330017566681, 10.34216170024966]);
    }

    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: centerCoordinates,
        zoom: 18,
      }),
    });

    // Create a marker feature
    const marker = new Feature({
      geometry: new Point(centerCoordinates),
    });

    // Set a custom icon for the marker
    const iconStyle = new Style({
      image: new Icon({
        src: LocationVector,
        scale: 1,
      }),
    });
    marker.setStyle(iconStyle);

    // Create a vector layer to display the marker
    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [marker],
      }),
    });

    // Add the vector layer to the map
    map.addLayer(vectorLayer);

    return () => {
      map.setTarget(null);
    };
  }, [site]);

  return <div ref={mapRef} className="h-full w-full" />;
};

export default MapComponent;
