import Nav from "../components/Nav/Nav";
import Banner from "../components/Banner/Banner";
import CUBody from "../components/Contact Us/CUBody";
import Forms from "../components/Forms/Forms";
import Footer from "../components/Footer/Footer";
import Maps from "../components/Maps/Maps";
import CUMaps from "../components/Contact Us/CUMaps";
import { useLocation } from "react-router-dom";
import { useEffect} from "react";
import ScrollToTopButton from "../components/Scroll/ScrollToTopButton";
const ContactUs = () => {
  const location = useLocation();
  useEffect(() => {
    if(!location.state.scrollToBody && !location.state.scrollToForm)
    window.scrollTo(0, 0);
  }, [ !location.state]);
  return (
    <>
      <Nav
        navClassName="absolute bg-white bg-opacity-70 backdrop-blur text-black"
        navContainerClassName="my-[25px] mx-[60px]"
      />
      <Banner page="Contact Us" bannerType="contactus-banner" />
      <CUBody scrollTo={(location.state && location.state.scrollToBody) ? location.state.scrollToBody : ''} />
      <Forms formType="contact" scrollTo={(location.state && location.state.scrollToForm) ? location.state.scrollToForm : ''} />
      <CUMaps />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default ContactUs;
