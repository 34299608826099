import AUVideo from "../../../videos/hero_au.mp4";
import React, { useRef, useState } from "react";
import HeroAUVideoButton from "./HeroAUVideoButton";
const HeroAUVideo = () => {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoToggle = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsVideoPlaying(true);
    } else {
      video.pause();
      setIsVideoPlaying(false);
    }
  };
  return (
    <>
      <div className="heroau-video-container relative flex items-center justify-center">
        <video src={AUVideo} ref={videoRef} className="h-[100%]" />
        <button
          onClick={handleVideoToggle}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
        >
          {isVideoPlaying ? (
            <HeroAUVideoButton isPlaying="false" />
          ) : (
            <HeroAUVideoButton />
          )}
        </button>
      </div>
    </>
  );
};

export default HeroAUVideo;
