import { Link } from "react-router-dom";
import DefaultButton from "../Buttons/DefaultButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const MobileNav = () => {
  const fbIcon = <FontAwesomeIcon icon={faFacebookF} />;
  const linkedinIcon = <FontAwesomeIcon icon={faLinkedinIn} />;

  const handleDownloadPoster = () => {

    const fileName = 'Company_Profile_2023.pdf';

    // Construct the URL for the file in the public folder
    const fileUrl = `${process.env.PUBLIC_URL}/downloadable_files/${fileName}`;
    console.log(fileUrl)

      // Create a temporary link element and trigger a click event
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

  }

  return (
    <>
      <div className="hidden laptop:flex fixed top-[114px] left-0 w-screen h-screen bg-white bg-opacity-100 backdrop-blur text-black items-center justify-center">
        {/* Mobile navigation content */}
        <div className="w-full my-auto px-[20px] pb-[114px] flex flex-col text-center justify-center items-center gap-[40px]">
          <div className="flex flex-col gap-[20px]">
            <Link
              className="text-[24px] leading-[27px] tracking-[-0.03em] capitalize"
              to="/"
            >
              Home
            </Link>
            <Link
              className="text-[24px] leading-[27px] tracking-[-0.03em] capitalize"
              to="/solutions"
            >
              Solutions
            </Link>
            <Link
              to="/accomplishments"
              className="text-[24px] leading-[27px] tracking-[-0.03em] capitalize"
            >
              Accomplishments
            </Link>
            <Link
              to="/partnerships"
              className="text-[24px] leading-[27px] tracking-[-0.03em] capitalize"
              state={{ scrollToBody: false }}
            >
              Be A Partner
            </Link>
            <Link
              to="/about-us"
              className="text-[24px] leading-[27px] tracking-[-0.03em] capitalize"
              state={{ scrollToBody: false }}
            >
              About Us
            </Link>
            <Link
              to="/careers"
              className="text-[24px] leading-[27px] tracking-[-0.03em] capitalize"
              state={{ scrollToBody: false }}
            >
              Careers
            </Link>
            <Link
              to="/contact-us"
              className="text-[24px] leading-[27px] tracking-[-0.03em] capitalize"
              state={{ scrollToBody: false }}
            >
              Contact Us
            </Link>
          </div>

          <div className="w-full ">
            <div className="h-[2px] w-full bg-[#DDDDDD]"></div>
          </div>

          <DefaultButton color="black" isMobile={true} onClick={handleDownloadPoster}>
            Download Poster
          </DefaultButton>

          <div className="flex flex-row gap-[20px]">
            <Link to="https://web.facebook.com/wallsinmotionincph/" target="_blank" className="">
              {fbIcon}
            </Link>
            <Link to="https://www.linkedin.com/company/wallsinmotioninc/" target="_blank" className="">
              {linkedinIcon}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
