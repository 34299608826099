import { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import HeroAU from "../components/Hero/About Us/HeroAU";
import HeroBanner from "../components/Hero/Banner/HeroBanner";
import HeroClient from "../components/Hero/Client/HeroClient";
import HeroContact from "../components/Hero/Contact Us/HeroContact";
import HeroSolutions from "../components/Hero/Solutions/HeroSolutions";
import Nav from "../components/Nav/Nav";
import ScrollToTopButton from "../components/Scroll/ScrollToTopButton";

const Hero = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="overflow-x-hidden wrapper">
        <Nav />
        <HeroBanner />
        <HeroAU />
        <HeroClient />
        <HeroSolutions />
        <HeroContact />
        <Footer />
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default Hero;
