const Phone = () => {
  return (
    <svg
      className="phoneSvg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="phoneCircle" cx="40" cy="40" r="40" fill="#F2F2F2" />
      <path
        d="M36.7979 30.4766C36.5244 29.7344 35.7041 29.3828 34.9619 29.5781L31.5244 30.5156C30.8604 30.7109 30.3916 31.2969 30.3916 32C30.3916 41.6875 38.2041 49.5 47.8916 49.5C48.5947 49.5 49.1807 49.0312 49.376 48.3672L50.3135 44.9297C50.5088 44.1875 50.1572 43.3672 49.415 43.0938L45.665 41.5312C45.04 41.2578 44.2979 41.4141 43.8682 41.9609L42.2666 43.875C39.5322 42.5859 37.3057 40.3594 36.0166 37.625L37.9307 36.0234C38.4775 35.5938 38.6338 34.8516 38.3604 34.2266L36.7979 30.4766Z"
        fill="#222222"
      />
    </svg>
  );
};

export default Phone;
