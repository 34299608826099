import { Link } from "react-router-dom";
import DefaultButton from "../../Buttons/DefaultButton";
import HeroAUVideo from "./HeroAUVideo";
import Fade from "react-reveal/Fade";
import { BuildImageURL, GetPublicId } from "../../../utils/ImageBuilder";

const HeroAU = () => {
  const aboutBottom =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685525437/WinM_Assets/cta-bg_etqqcg.png";
  const publicId = GetPublicId(aboutBottom);
  const aboutBottomUrl = BuildImageURL(publicId);
  return (
    <>
      <div className="flex flex-col relative">
        <div className="bg-white px-[120px] py-[90px] mobile:px-[20px] mobile:py-[60px] ">
          <div className="container-fluid flex flex-row gap-[100px] tablet:flex-col tablet:gap-[30px]">
            <div className="about-left flex flex-col flex-1">
              <div className="about-title flex flex-col gap-[15px]">
                <Fade top delay={300}>
                  <span className="text-[12px] leading-[0.2em] text-[#878787] mr-auto uppercase">
                    About Us
                  </span>
                </Fade>
                <Fade top delay={400}>
                  <div className="text-[42px] leading-[45px]  text-left mobile:text-[26px] mobile:leading-[45px]">
                    <span className="text-[#016EB8]">Transform Your Space</span>{" "}
                    with Our Advanced Movable Walls & Partitions
                  </div>
                </Fade>
              </div>
            </div>

            <div className="about-right text-left flex flex-col ml-auto gap-[25px]  flex-1">
              <Fade right delay={500}>
                <span className="">
                  We are a leading space management solutions company bringing
                  in advanced movable walls and partitions. We help our clients
                  elevate their plans and structures by providing safe and
                  quality service at a reasonable cost.
                </span>
              </Fade>
              <Fade right delay={600}>
                <span className="">
                  Now that we have reached our 25th-year milestone, our
                  portfolio has expanded to cinema ceiling acoustic systems,
                  water wall foundations, architectural aluminum systems, and
                  many more
                </span>
              </Fade>
            </div>
          </div>
        </div>
        <div className="hero-auv px-[120px] mobile:px-[20px]">
          <HeroAUVideo />
        </div>

        {/* <video className="mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" src/> */}
        <div
          className=" bg-blue about-bottom flex px-[120px] py-[90px] mobile:px-[20px] mobile:py-[60px] "
          style={{ backgroundImage: `url(${aboutBottomUrl})` }}
        >
          <div className="container-fluid flex flex-row mt-auto mx-auto text-white gap-[190px] tablet:flex-col tablet:gap-[45px] mobile:px-[20px] ">
            <Fade delay={300}>
              <span className="text-[42px] leading-[45px] tracking-[-0.03em] tablet:text-center tablet:justify-center tablet:items-center mobile:text-[26px] mobile:leading-[29px]">
                At Walls in Motion, we know that in order to change the world,{" "}
                <span className="text-[#4FB8FF]">
                  We just have to keep moving!{" "}
                </span>
              </span>
            </Fade>
            <Fade right delay={300}>
              <div className="my-auto tablet:mx-auto">
                <Link to="/about-us">
                  <DefaultButton route="">More About Us</DefaultButton>
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroAU;
