import DefaultButton from "../../Buttons/DefaultButton";

import { useSolution } from "../../../contexts/SolutionsContext";
import { useNavigate } from "react-router-dom";

const SolutionsImages = ({ gallery }) => {
  const navigate = useNavigate();

  const { setActiveSolution } = useSolution();
  return (
    <>
      <div className="tablet:mx-auto tablet:basis-[100%] lg:basis-[32%] w-full">
        <div className="relative group h-full w-full">
          <img
            src={gallery.solution_img}
            alt="Solutions"
            className="w-full h-full object-cover"
          />
          <div className="absolute top-0 left-0 w-full h-full opacity-0 group-hover:opacity-[92%] bg-[#016EB8] transition duration-300">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white flex flex-col gap-[12px] text-center">
              <span className="text-[18px] leading-[23px] tracking-[-0.03em]">
                {gallery.solution_name}
              </span>
              <span className="text-[15px] leading-[23px] tracking-[-0.03em]">
                {gallery.solution_desc}
              </span>
              <div className="mt-[13px]">
                <DefaultButton
                  onClick={() => {
                    setActiveSolution(gallery);
                    navigate("/solution/show");
                  }}
                  setGallery={gallery}
                  isOverlay={true}
                  state={{ scrollToBody: false }}
                >
                  Learn More
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolutionsImages;
