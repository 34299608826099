import { useState, useEffect } from "react";
import { BuildImageURL, GetPublicId } from "../../utils/ImageBuilder";
import DefaultButton from "../Buttons/DefaultButton";
import InputBox from "../Input/InputBox";
import Partner from "./Types/Partner";
import SendEmail from '../../api_functions/SendEmail'

const Forms = ({ formType, scrollTo }) => {
  const formBg =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685528459/WinM_Assets/forms-bg_z0aud1.png";
  const publicId = GetPublicId(formBg);
  const formBgUrl = BuildImageURL(publicId);

  

  const Partnerships = () => {
    return <Partner />;
  };

  // const Careers = () => {
  //   return (
  //     <div
  //       className="form-careers px-[220px] py-[90px] tablet:px-[120px] mobile:px-[20px] mobile:py-[60px]"
  //       style={{ backgroundImage: `url(${formBgUrl})` }}
  //     >
  //       <div className="flex flex-col  gap-[40px]">
  //         <div className="flex flex-col text-white">
  //           <div className="text-[36px] leading-[39px]  text-center justify-center align-center mobile:text-left">
  //             Be part of this{" "}
  //             <span className="text-[#016EB8]">Winning Teams!</span>
  //           </div>
  //           <div className="text-[15px] leading-[23px] tracking-[-0.03em] mt-[20px] text-center justify-center align-center mobile:text-left">
  //             Send us your info and our human resource team will get in touch
  //             with you as soon as possible.
  //           </div>
  //         </div>
  //         <div className="flex flex-col gap-[16px]">
  //           <div className="flex flex-row  gap-[16px] tablet:flex-col">
  //             <div className="flex flex-col flex-1 text-white gap-[12px]">
  //               <div className="text-[#222222] text-white">
  //                 Full Name <span className="text-[#E25454]">*</span>
  //               </div>
  //               <div className="">
  //                 <InputBox
  //                   type="text"
  //                   placeholder="Your full name here..."
  //                   custom="bg-transparent"
  //                 />
  //               </div>
  //             </div>
  //             <div className="flex flex-col flex-1 text-white gap-[12px]">
  //               <div className="text-[#222222] text-white ">
  //                 Email Address <span className="text-[#E25454]">*</span>
  //               </div>
  //               <div className="">
  //                 <InputBox
  //                   type="text"
  //                   placeholder="Your email address here..."
  //                   custom="bg-transparent"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="flex flex-row gap-[16px] tablet:flex-col">
  //             <div className="flex flex-col  flex-1 text-white gap-[12px]">
  //               <div className="text-[#222222] text-white">
  //                 Phone Number <span className="text-[#E25454]">*</span>
  //               </div>
  //               <div className="">
  //                 <InputBox
  //                   type="text"
  //                   placeholder="Your phone number here..."
  //                   custom="bg-transparent"
  //                 />
  //               </div>
  //             </div>
  //             <div className="flex flex-col flex-1 text-white  gap-[12px]">
  //               <div className="text-[#222222] text-white">
  //                 Available positions <span className="text-[#E25454]">*</span>
  //               </div>
  //               <div className="">
  //                 <InputBox
  //                   type="dropdown"
  //                   data="positions"
  //                   placeholder=""
  //                   custom="bg-transparent"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="flex flex-col flex-1 text-white gap-[12px]">
  //             <div className="text-[#222222] text-white">
  //               Cover letter (Optional)
  //             </div>
  //             <div className="">
  //               <InputBox
  //                 type="textarea"
  //                 taHeight="125"
  //                 placeholder="Your cover letter here..."
  //                 custom="bg-transparent"
  //               />
  //             </div>
  //           </div>
  //           <div className="flex flex-col flex-1 text-white gap-[12px]">
  //             <div className="text-[#222222] text-white">
  //               Upload CV or Resume <span className="text-[#E25454]">*</span>
  //             </div>
  //             <div className="">
  //               <InputBox
  //                 type="upload"
  //                 taHeight="125"
  //                 placeholder="Test placeholder"
  //                 custom="bg-transparent"
  //               />
  //             </div>
  //           </div>
  //         </div>

  //         <div className="mx-auto">
  //           <DefaultButton>Submit Message</DefaultButton>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const Contact = (scrollTo) => {
    const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState('')
    const [formError, setFormError] = useState(false)
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [purpose,setPurpose] = useState('');
    const [message,setMessage] = useState('');


    const [errors,setErrors] = useState({name:'',email: '', phone: '', purpose: '', message: ''});

    useEffect(() => {
      if (scrollTo) {
        const form = document.getElementById('contact_form');
        if (form) {
          // form.scrollIntoView({  behavior: "smooth", offsetTop:114 });
          const offset = 114; // Adjust this value as needed
          const rect = form.getBoundingClientRect();
          const scrollTop = window.scrollY;
          const targetScrollTop = rect.top + scrollTop - offset;

          window.scrollTo({
            top: targetScrollTop,
            behavior: "smooth",
          });
      
        }
      }
    }, [scrollTo]);

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleEmailChange = (e) => {
      setEmail(e.target.value)
    }

    const handlePhoneChange = (e) => {
      setPhone(e.target.value)
    }

    const handlePurposeChange = (e) => {
      setPurpose(e.target.value)
    }

    const handleMessageChange = (e) => {
      setMessage(e.target.value)
    }

    const setFieldError = (fieldName, errorMessage) => {
      setErrors((prevErrors) => {
        const updatedErrors = {...prevErrors};
        updatedErrors[fieldName] =  errorMessage
        return updatedErrors
      });
    };
    const resetFieldErrors = () => {
      setErrors((prevErrors) => {
        const updatedErrors = {};      
        Object.keys(prevErrors).forEach((key) => {
          updatedErrors[key] = '';
        });
      
        return updatedErrors;
      });
    };

    const resetFormFields = () => {
      setName('');
      setEmail('');
      setPhone('');
      setPurpose('');
      setMessage('');
    }

    const getErrorsCount = () => {
      const count =  Object.values(errors).filter(error => error !== '').length;
      return count;
    }

    const validateForm = () => {

      var error = false;
    
      if (!name.trim()) {
        setFieldError('name', 'Name is required.')
        error = true;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setFieldError('email', 'A valid email address required e.g. you@example.com');
        error = true;
      }

      const phoneRegex = /^[0-9+]+$/;
      if (!phoneRegex.test(phone.trim())) {
        setFieldError('phone', 'A valid phone number required e.g. +631234567890');
        error = true;
      }

      if(!purpose.trim()) {
        setFieldError('purpose', 'Purpose is required.')
        error = true;
      }

      if(!message.trim()) {
        setFieldError('message', 'Message is required.')
        error = true;
      }
      return error
    }



    const handleFormSubmission = async() => {
      setSubmitting(true)
      setFormMessage('');
      setFormError(false);
      resetFieldErrors();
     
      if(validateForm()){
        setFormMessage('Invalid inputs, please correct the errors!')
        setSubmitting(false)
      }else{
        
        const formData = {
          name: name.trim(),
          email:email.trim(),
          phone:phone.trim(),
          purpose:purpose,
          message:message.trim(),
          type:'contact_us'
        }
        try {
          const result = await SendEmail(false,formData);
          if (result.success) {
            setFormMessage('Form has been submitted successfully!');
            resetFormFields()
          } else {
            if(result.type==='error'){
              setFormMessage(result.message);
              setFormError(true)
            }else if(result.type==='errors'){
              setErrors(result.message);
              setFormMessage('Invalid inputs, please correct the errors!');
            }else{
              setFormError(true)
              setFormMessage('Something went wrong. Try again!');
            }
            
          }
        } catch (error) {
          setFormError(true)
          setFormMessage(error.message);
        }
        setSubmitting(false)
      }
    }

    
    return (
      <div
        className="form-partner px-[220px] py-[90px] mobile:px-[20px] mobile:py-[60px]"
        style={{ backgroundImage: `url(${formBgUrl})` } }
        id="contact_form"
      >
        <div className="flex flex-col  gap-[40px]" >
          <div className="flex flex-col text-white">
            <div className="text-[36px] leading-[39px]  text-center justify-center align-center mobile:text-left">
              How can we help?{" "}
              <span className="text-[#016EB8]">Send us a message.</span>
            </div>
            <div className="text-[15px] leading-[23px] tracking-[-0.03em] mt-[20px] text-center justify-center align-center mobile:text-left">
              Our Space Experts are always ready to assist you!
            </div>
            { formMessage ? <div className={`p-5 text-base text-white rounded mt-4 w-full mx-auto ${getErrorsCount() > 0  || formError ? 'bg-red-500' : 'bg-green-500'}`}>{formMessage}</div> : ''  }
          </div>
          <div className="flex flex-col  gap-[16px]">
            <div className="flex flex-row  gap-[16px] mobile:flex-col">
              <div className="flex flex-col flex-1 text-white gap-[12px]">
                <div className="text-white">
                  Full Name <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  
                  <InputBox
                    type="text"
                    placeholder="Your full name here..."
                    custom={`bg-transparent text-white ${errors.name ? 'border-red-500' : ''}`}
                    value={name}
                    onChange={handleNameChange}
                  />
                  {errors.name ? <div className="text-sm text-red-500 mt-1">{errors.name}</div> : ''}
                </div>
              </div>
              <div className="flex flex-col flex-1 text-white gap-[12px]">
                <div className="text-white ">
                  Email Address <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                  <InputBox
                    type="email"
                    placeholder="Your email here... "
                    custom={`bg-transparent text-white ${errors.email ? 'border-red-500' : ''}`}
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {errors.email ? <div className="text-sm text-red-500 mt-1">{errors.email}</div> : ''}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-[16px] mobile:flex-col">
              <div className="flex flex-col  flex-1 text-white gap-[12px]">
                <div className=" text-white">
                  Phone Number <span className="text-[#E25454]">*</span>
                </div>
                <div className=""> 
                  <InputBox
                    type="text"
                    placeholder="e.g. +631234567890"
                    custom={`bg-transparent text-white ${errors.phone ? 'border-red-500' : ''}`}
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  {errors.phone ? <div className="text-sm text-red-500 mt-1">{errors.phone}</div> : ''}
                </div>
              </div>
              <div className="flex flex-col flex-1 text-white  gap-[12px]">
                <div className=" text-white">
                  Inquiry purpose <span className="text-[#E25454]">*</span>
                </div>
                <div className="">
                
                  <div className="relative">
                    <select
                      placeholder=""
                      custom={`g-transparent `}
                      value={purpose}
                      onChange={handlePurposeChange}
                      className={`border-[1px] text-white px-[18px] border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent my-auto appearance-none ${errors.purpose ? 'border-red-500' : ''}`}
                    >
                      <option className="text-opacity-50" value="" disabled>
                        Please select one
                      </option>
                      <option
                        className="text-black hover:bg-black"
                        value="Solutions Inquiry"
                      >
                        Solutions Inquiry
                      </option>
                      <option className="text-black" value="Service and Maintenance">
                        Service and Maintenance
                      </option>
                      <option className="text-black" value="Marketing and Events">
                        Marketing and Events
                      </option>
                      <option className="text-black" value="Partnerships">
                        Partnerships
                      </option>
                    </select>

                    <div className="text-white absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <svg
                        width="15"
                        height="10"
                        viewBox="0 0 15 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=""
                      >
                        <path
                          d="M13 2L7.16667 9L1.33333 2"
                          stroke="#FFFFFF"
                          strokeWidth="1.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                          className="text-black text-opacity-50"
                        />
                      </svg>
                    </div>
                  </div>
                  {errors.purpose ? <div className="text-sm text-red-500 mt-1">{errors.purpose}</div> : ''}
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 text-white gap-[12px]">
              <div className=" text-white">
                Message <span className="text-[#E25454]">*</span>
              </div>
              <div className="">
                <InputBox
                  type="textarea"
                  taHeight="125"
                  placeholder="Your Message here.."
                  custom={`bg-transparent text-white ${errors.message ? 'border-red-500' : ''}`}
                  value={message}
                  onChange={handleMessageChange}
                />
                {errors.message ? <div className="text-sm text-red-500">{errors.message}</div> : ''}
              </div>
            </div>
          </div>
          <div className="mx-auto">
            <DefaultButton onClick={handleFormSubmission} customClasses={submitting ? 'pointer-events-none opacity-50' : ''}>{submitting ? 'Submitting...' : 'Submit'}</DefaultButton>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {formType === "partner"
        ? Partnerships()
        // : formType === "careers"
        // ? Careers()
        : formType === "contact"
        ? Contact(scrollTo)
        : null}
    </>
  );
};

export default Forms;
