import React, {useState } from "react";
import { useDropzone } from "react-dropzone";

const InputBox = ({
  placeholder,
  type,
  data,
  taHeight,
  custom,
  value,
  onChange,
  error,
  attachKey
}) => {
  const [selectedOption, setSelectedOption] = useState();
  // const [isLabelDisabled, setLabelDisabled] = useState(false);
  
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const clearSelectedFile = (event) =>{
     event.stopPropagation();
     onChange(null);
  }

  const onDrop = (acceptedFiles) => {
    // Do something with the dropped files
    const file = acceptedFiles[0];
    // setSelectedFile(file);
    onChange(file)
  };

  // const handleFileSelection = (e) => {
  //   const file = e.target.files[0];
  //   // setSelectedFile(file);
  //   onChange(file)
  // };
  // const handleLabelClick = () => {
  //   if (!isLabelDisabled) {
  //     setLabelDisabled(true);

  //     // You can trigger the file input click programmatically
  //     const fileInput = document.getElementById('fileInput');
  //     fileInput.click();

  //     // You can also listen for the file selection event and re-enable the label
  //     fileInput.addEventListener('change', () => {
  //       setLabelDisabled(false);
  //     });
  //     setTimeout(() => {
  //       setLabelDisabled(false);
  //     }, 1000);
      
  //   }
  // };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const Default = () => {
    return (
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] ${custom}`}
      />
    );
  };

  const Disabled = () => {
    return (
      <input
        type="text"
        placeholder={placeholder}
        className={`border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] ${custom}`}
        disabled
        value={value}
      />
    );
  };

  const Number = () => {
    return (
      <input
        type="number"
        placeholder={placeholder}
        className={`border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] ${custom}`}
        value={value}
        onChange={onChange}
      />
    );
  };

  const Email = () => {
    return (
      <input
        type="email"
        placeholder={placeholder}
        className={`border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] ${custom}`}
        value={value}
        onChange={onChange}
      />
    );
  };

  const Datalist = () => {
    const Subscription = () => {
      return (
        <>
          <select
            placeholder={placeholder}
            className="border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8]"
            style={{ color: "#222222", opacity: 0.5 }}
            value={selectedOption}
            onChange={handleChange}
          >
            <option className="hidden" value="" disabled>
              Average: ₱7,000
            </option>
            <option value="option1">sub 1</option>
            <option value="option2">sub 2</option>
            <option value="option3">sub 3</option>
          </select>
        </>
      );
    };
    const Setup = () => {
      return (
        <select
          placeholder={placeholder}
          className="border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8]"
          style={{ color: "#222222", opacity: 0.5 }}
        >
          <option className="hidden" value="" disabled>
            Average: ₱7,000
          </option>
          <option value="option1">set 1</option>
          <option value="option2">set 2</option>
          <option value="option3">set 3</option>
        </select>
      );
    };
    const Partnership = () => {
      return (
        <div className="relative">
          <select
            defaultValue=""
            className="border-[1px] text-white text-opacity-50 px-[18px] my-[12px] border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent my-auto appearance-none"
          >
            <option className=" text-opacity-50" value="" disabled>
              Please select one
            </option>
            <option className="text-black" value="option1">
              Project 1
            </option>
            <option className="text-black" value="option2">
              proj 2
            </option>
            <option className="text-black" value="option3">
              proj 3
            </option>
          </select>
          <div className="text-white absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=""
            >
              <path
                d="M13 2L7.16667 9L1.33333 2"
                stroke="#FFFFFF"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
                className="text-black text-opacity-50"
              />
            </svg>
          </div>
        </div>
      );
    };
    const Positions = () => {
      return (
        <div className="relative">
          <select
            onChange={onChange}
            value={value}
            className={`border-[1px] text-white px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent my-auto appearance-none ${custom}`}
          >
            <option className=" text-opacity-50" value="" disabled hidden>
              Please select one
            </option>
            <option className="text-black" value="Accounting Supervisor">
              Accounting Supervisor
            </option>
            <option className="text-black" value="Payroll Officer">
              Payroll Officer
            </option>
            <option className="text-black" value="Electro Mechanical Engineer">
              Electro Mechanical Engineer
            </option>
            <option className="text-black" value="oHR Supervisor">
              HR Supervisor
            </option>
            <option className="text-black" value="Sales Account Manager">
              Sales Account Manager
            </option>
            <option className="text-black" value="Sales Account Manager - Cebu Branch">
              Sales Account Manager - Cebu Branch
            </option>
            <option className="text-black" value="Project Evaluator/ Canvasser">
              Project Evaluator/ Canvasser
            </option>
          </select>
          <div className="text-white absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=""
            >
              <path
                d="M13 2L7.16667 9L1.33333 2"
                stroke="#FFFFFF"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
                className="text-black text-opacity-50"
              />
            </svg>
          </div>
        </div>
      );
    };
    const Inquiry = () => {
      return (
        <select
          placeholder={placeholder}
          className="border-[1px] px-[18px]  border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent text-white text-opacity-50 my-auto"
        >
          <option className="hidden" value="" disabled>
            Please select one
          </option>
          <option value="option1">inq 1</option>
          <option value="option2">inq 2</option>
          <option value="option3">inq 3</option>
        </select>
      );
    };
    const Projects = () => {
      return (
        <div className="relative">
          <select
            placeholder={placeholder}
            defaultValue=""
            className="border-[1px] px-[18px] my-[12px] border-solid w-full rounded h-[45px] focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] bg-transparent my-auto appearance-none"
          >
            <option className="text-black text-opacity-50" value="" disabled>
              Please select one
            </option>
            <option value="option1">Project 1</option>
            <option value="option2">Project 2</option>
            <option value="option3">Project 3</option>
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 2L7.16667 9L1.33333 2"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      );
    };
    return (
      <>
        {data === "setup"
          ? Setup()
          : data === "subscription"
          ? Subscription()
          : data === "partnership"
          ? Partnership()
          : data === "positions"
          ? Positions()
          : data === "inquiry"
          ? Inquiry()
          : data === "projects"
          ? Projects()
          : null}
      </>
    );
  };

  const Textarea = () => {
    return (
      <textarea
        type="text"
        value={value}
        onChange={onChange}
        placeholder="Your message here..."
        className={`px-[18px] py-[12px] border-[1px] border-solid w-full rounded  focus:border-[#016EB8] focus:outline-none focus:ring-2 focus:ring-[#016EB8] h-[${taHeight}] ${custom}`}
      />
    );
  };
  const Upload = () => {
    return (
      <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""} bg-transparent border-2 border-dashed rounded-[5px] flex justify-center items-center h-full py-[33px] px-[260px] tablet:px-[120px] mobile:px-[60px] ${custom ? custom : 'border-white'}`}>
        <input {...getInputProps()} />
        <div className="flex flex-row gap-[11px]">
        {isDragActive ? (
          <div className="text-[15px] mx-auto">
            Drop the file here
          </div>
        ) : (
          <>
          {!value ? (
            <>
              <div className="my-auto">
                <svg
                  width="22"
                  height="17"
                  viewBox="0 0 22 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="my-auto"
                >
                  <path
                    d="M10.7549 13.0154V6.26536M10.7549 6.26536L13.7549 9.26536M10.7549 6.26536L7.75489 9.26536M5.50489 16.0154C4.43433 16.0165 3.39841 15.636 2.58319 14.9421C1.76797 14.2481 1.22685 13.2863 1.057 12.2293C0.887158 11.1723 1.09972 10.0893 1.65651 9.17496C2.21331 8.26057 3.07785 7.57465 4.09489 7.24037C3.83359 5.90153 4.10365 4.51357 4.84788 3.37038C5.5921 2.2272 6.75206 1.41856 8.08211 1.11572C9.41216 0.812871 10.8079 1.0396 11.9737 1.74788C13.1394 2.45616 13.9838 3.59039 14.3279 4.91036C14.8599 4.73734 15.4297 4.71652 15.9729 4.85026C16.5161 4.984 17.0111 5.26697 17.402 5.6672C17.7929 6.06744 18.0641 6.569 18.1849 7.11523C18.3058 7.66146 18.2715 8.2306 18.0859 8.75836C18.9045 9.07107 19.5879 9.66046 20.0175 10.4243C20.447 11.1882 20.5956 12.0783 20.4375 12.9402C20.2794 13.8022 19.8246 14.5816 19.1519 15.1433C18.4793 15.705 17.6312 16.0135 16.7549 16.0154H5.50489Z"
                    stroke="#E6E6E6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="text-[15px] mx-auto">
                Drag and drop your file here or{" "}
                <label className={`text-[#4FB8FF] text-underline inline cursor-pointer `}>browse</label><br/> <span className={`text-xs ${error ? 'text-red-500' : 'text-gray-300'}`}>{error ? error : 'Allowed file: PDF, max-size: 5MB'}</span>
              </div>
              </>) : ('')}
          </>
        )}
        {(!isDragActive && value ? (
            <div className="text-[15px] mx-auto">
            Selected File: <span className="text-[#4FB8FF]">{value.name}</span> <span onClick={clearSelectedFile} title="Clear File" className=" ml-2 inline cursor-pointer"><svg className="inline w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path strokeLinecap="round" strokeLinejoin="round" d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg></span>
            </div>
          ) : ("") 
        )}
        {/* <input
          type="file"
          id="fileInput"
          key={attachKey}
          style={{ display: 'none' }}
          onChange={handleFileSelection}
          accept=".pdf"
          
        /> */}
          
        </div>
        
      </div>

      // <div className='border-0.5 rounded-[5px] border-dashed border-white flex w-full py-[30px] px-[400px]'>

      // </div>
    );
  };
  return (
    <>
      {type === "number"
        ? Number()
        : type === 'email'
        ? Email()
        : type === "dropdown"
        ? Datalist()
        : type === "textarea"
        ? Textarea()
        : type === "upload"
        ? Upload()
        : type === "disabled"
        ? Disabled()
        : Default()}
    </>
  );
};

export default InputBox;
