import { useNavigate } from "react-router-dom";

const DefaultButton = ({
  children,
  onClick,
  color,
  route,
  isMobile,
  isOverlay,
  customClasses,
}) => {
  const navigate = useNavigate();
  const handleRouteAnchor = () => {
    if (route === "partner") {
      navigate("/partnerships", { state: { scrollToBody: true } });
    } else if (route === "client") {
      navigate("/contact-us", { state: { scrollToBody: true } });
    } else if (route === "getaquote") {
      navigate("/contact-us", { state: { scrollToForm: true } });
    } else if (route === "partners-form") {
      navigate("/partnerships", { state: { scrollToPartnersForm: true } });
    } else if (route === "careers-form") {
      navigate("/careers", { state: { scrollToCareersForm: true } });
      const careersForm = document.getElementById("careers-form");
      if (careersForm) {
        const offset = 114; // Adjust this value as needed
        const rect = careersForm.getBoundingClientRect();
        const scrollTop = window.scrollY;
        const targetScrollTop = rect.top + scrollTop - offset;

        window.scrollTo({
          top: targetScrollTop,
          behavior: "smooth",
        });
      }
    } else if (route === "solutions") {
      navigate("/solutions");
    } else {
      navigate("/");
    }
  };

  const handleClick = () => {
    route ? handleRouteAnchor() : onClick && onClick();
  };

  const White = () => {
    return (
      <div className="">
        <button
          className={
            isOverlay
              ? `text-white border-white border-[1px] rounded-[5px] px-[25px] py-[15px] hover:bg-[#ffffff] hover:text-[#016EB8] transition duration-500 whitespace-nowrap ${customClasses}`
              : `text-white border-white border-[1px] rounded-[5px] px-[25px] py-[15px] hover:bg-[#016EB8] transition duration-500 hover:border-[#016EB8] whitespace-nowrap ${customClasses}`
          }
          onClick={handleClick}
        >
          <span className="">{children}</span>
        </button>
      </div>
    );
  };
  const Black = () => {
    return (
      <div className={isMobile ? `w-full` : ``}>
        <button
          className={
            isMobile
              ? `w-full border-black border-[1px] rounded-[5px]  py-[15px] hover:bg-[#016EB8] text-black transition duration-500 hover:border-[#016EB8] hover:text-white ${customClasses}`
              : `border-black border-[1px] rounded-[5px] px-[25px] py-[15px] hover:bg-[#016EB8] text-black transition duration-500 hover:border-[#016EB8] hover:text-white whitespace-nowrap ${customClasses}`
          }
          onClick={handleClick}
        >
          <span className="">{children}</span>
        </button>
      </div>
    );
  };

  const Gray = () => {
    return (
      <div className="">
        <button
          className={`border-[#E6E6E6] bg-[#E6E6E6] border-[1px] rounded-[5px] px-[25px] py-[15px] hover:bg-[#E6E6E6] transition duration-500 hover:border-[#E6E6E6] whitespace-nowrap ${customClasses}`}
          onClick={onClick}
        >
          <span className="text-black">{children}</span>
        </button>
      </div>
    );
  };

  return <>{color === "black" ? Black() : color === "gray" ? Gray() : White()}</>;
};

export default DefaultButton;
