import DefaultButton from "../Buttons/DefaultButton";

import { useSolution } from "../../contexts/SolutionsContext";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Table = ({ gallery }) => {
  const navigate = useNavigate();

  const { setActiveSolution } = useSolution();
  return (
    <>
      <Fade bottom delay={300} distance="50%">
        <div className="flex md:basis-[50%] lg:basis-[32%] w-full">
          <div className="relative group flex-1">
            <img
              src={gallery.team_img}
              alt="Solutions"
              className="w-full h-auto object-cover"
            />
            <div className="absolute top-0 left-0 w-full h-full opacity-0 group-hover:opacity-[92%] bg-[#016EB8] transition duration-300">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white flex flex-col gap-[12px] text-center">
                <span className="text-[18px] leading-[23px]">
                  {gallery.team_name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Table;
