import { Link } from "react-router-dom";
import DefaultButton from "../Buttons/DefaultButton";
import { BuildImageURL, GetPublicId } from "../../utils/ImageBuilder";
import Fade from "react-reveal/Fade";

const CTA = () => {
  const imageUrl =
    "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685525437/WinM_Assets/generic_cta_ha8e0o.png";
  const publicId = GetPublicId(imageUrl);
  const finalImageUrl = BuildImageURL(publicId);

  return (
    <>
      <div
        className="cta w-full py-[125px] px-[120px] mobile:px-[70px] mobile:py-[60px]"
        style={{ backgroundImage: `url(${finalImageUrl})` }}
      >
        <div className="flex flex-col gap-[50px]">
          <Fade top delay={400} distance="20%">
            <div className="text-[42px] leading-[45px] text-center text-white">
              Got any questions?{" "}
              <span className="text-[#016EB8]">We're here to help.</span>
            </div>
          </Fade>

          <Fade bottom delay={400} distance="20%">
            <div className="mx-auto">
              <Link to="/contact-us" state={{ scrollToBody: false }}>
                <DefaultButton>Contact Us</DefaultButton>
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default CTA;
