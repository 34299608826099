import { createContext, useState, useContext } from "react";

const MilestoneContext = createContext({});

const ms_img =
  "https://res.cloudinary.com/dvfu8fxlg/image/upload/v1685570093/WinM_Assets/ms-1996_cuke6w.png";
const Milestones = [
  {
    ms_year: "1996",
    ms_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1363,w_2048/v1688440492/WinM_Assets/milestones/1996_rdffoq.jpg",
    ms_desc:
      "David A. Kulseth (an American businessman) and Melanie de Leon established Walls in Motion in Manila, Philippines. They are backed by over twenty-four (24) years of experience in the manufacturing & installation of movable walls and partitions.",
    ms_title: "Walls In Motion was born",
    ms_id: 0,
  },
  {
    ms_year: "1997 – 1998",
    ms_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1363,w_2048/v1688440603/WinM_Assets/milestones/97-98_to8t1x.jpg",
    ms_desc:
      "The first product was ULTRAWALL®, a relocatable gypsum board wall system manufactured by United States Gypsum. Soon after, Walls in Motion was incorporated in the Philippines after the enormous growth potential of the construction industry in Asia.",
    ms_title: "Product Pioneer",
    ms_id: 1,
  },
  {
    ms_year: "1999 - 2006",
    ms_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1363,w_2048/v1688440561/WinM_Assets/milestones/97-06_cavwdp.jpg",
    ms_desc:
      "Through the collective effort of various shareholders and directors who shared the same vision with the team, Walls in Motion, Inc. diversified into other areas, particularly in operable walls and the QUIKWALL System.",
    ms_title: "Diversification",
    ms_id: 2,
  },
  {
    ms_year: "2007",
    ms_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1363,w_2048/v1688440490/WinM_Assets/milestones/07_yjzpp3.jpg",
    ms_desc:
      "Mr. Jorge A. Lichauco acquired the company. Since then, Walls in Motion, Inc. continued to become the industry leader in Movable Wall Partitioning, specified by architects, engineers, and designers.",
    ms_title: "Business Acquisition",
    ms_id: 3,
  },
  {
    ms_year: "2008 - 2021",
    ms_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1363,w_2048/v1688440487/WinM_Assets/milestones/08-21_kuzkmy.jpg",
    ms_desc:
      "Walls in Motion, Inc. expanded on the supply of Stair Nosing, Wall Guards, Theater Seats, Waterwall and Autex Polyester Products.",
    ms_title: "Expansion",
    ms_id: 4,
  },
  {
    ms_year: "2022",
    ms_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1363,w_2048/v1688440599/WinM_Assets/milestones/22_bdhu1f.jpg",
    ms_desc:
      "Walls in Motion, Inc. celebrated its 25th year in the movable walls & partitions industry.",
    ms_title: "25th Year Milestone",
    ms_id: 5,
  },
  {
    ms_year: "2022 - 2023",
    ms_img:
      "https://res.cloudinary.com/dvfu8fxlg/image/upload/c_scale,h_1363,w_2048/v1688795066/WinM_Assets/milestones/22-23_lflyws.jpg",
    ms_desc:
      "Walls in Motion, Inc., or WinM, continues to develop a diverse portfolio of interior construction products to meet the ever-growing demands of clients and industry partners.",
    ms_title: "We Keep Moving!",
    ms_id: 6,
  },
];

const useMilestone = () => useContext(MilestoneContext);

export { Milestones, useMilestone };
