import SolutionsGallery from "./Gallery/SolutionsGallery";
import { Solutions } from "../../contexts/SolutionsContext";
import Fade from "react-reveal/Fade";

const SolutionsBody = () => {
  return (
    <>
      <div className="bg-[#222222] ">
        <div className="px-[120px]  py-[90px] flex flex-col laptop:mx-0 mobile:px-[20px] mobile:py-[60px]">
          <Fade top delay={400}>
            <div className="text-white solutions-header text-[42px] leading-[45px] tracking-[-0.03em] text-center mobile:text-left mobile:text-[26px] mobile:leading-[29px]">
              Efficient Space Savers{" "}
              <span className="text-[#016EB8]">Made Easy</span>
            </div>
          </Fade>
          <Fade delay={600}>
            <div className="text-white text-[15px] leading-[23px] tracking-[-0.03em] mt-[30px] px-[230px] text-center justify-center laptop:px-0 tablet:px-0 mobile:px-0  mobile:text-left">
              <span>
                Our diverse space solutions have synergized the essential needs
                of architects, engineers, and interior designers. We assure our
                clients that we provide the best flexibility, privacy, and
                improved sound control in every space we work with. And we
                strive to match the aesthetic, materials, and styles that fit
                our client's design preferences.
              </span>
            </div>
          </Fade>

          <div className="solutions-gallery grid grid-cols-3 gap-[16px] mt-[90px] items-center justify-center laptop:grid-cols-2 tablet:grid-cols-1 mobile:mt-[60px]">
            <SolutionsGallery galleryData={Solutions} key={Solutions} />
          </div>
          {/* <div className='solutions-button mt-[60px] mx-auto'>
                        <DefaultButton color="black">
                            View All Solutions
                        </DefaultButton>
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default SolutionsBody;
