import SolutionsImages from "../Gallery/SolutionsImages";
const SolutionsGallery = ({ galleryData }) => {
  return (
    <>
      {galleryData?.map((gallery, key) => (
        <SolutionsImages gallery={gallery} key={key} />
      ))}
    </>
  );
};

export default SolutionsGallery;
