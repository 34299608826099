import { useState } from "react";
import { Accomplishments } from "../../contexts/AccomplishmentsContext";
import DefaultButton from "../Buttons/DefaultButton";
import ProjectsModal from "./Modal/ProjectsModal";
import Fade from "react-reveal/Fade";
const AccomplishmentsGallery = ({ selectedSector }) => {
  const sector = Accomplishments.find(
    (sector) => sector.sector_name === selectedSector
  );

  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  return (
    <>
      <div className="flex flex-col p-[90px] gap-[40px] mobile:px-[20px] mobile:py-[40px]">
        <Fade top delay={100}>
          <div className="text-left text-[36px] leading-[39px] tracking-[-0.03em]">
            {sector.sector_name}
          </div>
        </Fade>
        <div className="container flex gap-4 mx-auto  grid grid-cols-2 big-laptop:grid-cols-2 tablet:grid-cols-1">
          {sector.projects.map((project) => (
            <Fade delay={100}>
              <div key={project.name} className="z-0 flex">
                <div className="relative flex">
                  {project.images.length > 0 && (
                    <img
                      key={project.images[0].name}
                      src={project.images[0].url}
                      alt={project.images[0].name}
                      className="w-full hover:opacity-75 transition-opacity duration-300"
                    />
                  )}
                  <div className="flex flex-col gap-[20px] absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-[#016eb8] bg-opacity-90">
                    <p className="text-white text-[18px] leading-[18px] tracking-[-0.03em]">
                      {project.images[0].sol}
                    </p>
                    <DefaultButton
                      onClick={() => openModal(project)}
                      isOverlay={true}
                    >
                      View More
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
      {selectedProject && (
        <ProjectsModal project={selectedProject} onClose={closeModal} />
      )}
    </>
  );
};

export default AccomplishmentsGallery;
