import Slider from "react-slick";
import { Solutions, useSolution } from "../../../contexts/SolutionsContext";
import DefaultButton from "../../Buttons/DefaultButton";
import { useEffect, useRef } from "react";
import PrevArrow from "../Slide/PrevArrow";
import NextArrow from "../Slide/NextArrow";
import { useNavigate } from "react-router-dom/dist";

const SolutionsYMAL = ({ activeSolutionId, activeSolution }) => {
  const { setActiveSolution } = useSolution();
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const filteredSolutions = Solutions.filter(
    (solution) => solution.solution_id !== activeSolutionId
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    arrows: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="flex flex-col bg-[#1F1F1F] py-[90px] gap-[40px] mobile:py-[60px]">
        <div className="flex flex-row w-full px-[120px] tablet:gap-[20px] mobile:px-[20px] ">
          <div className="text-white text-[36px] leading-[36px] tracking-[-0.03em] tablet:text-[24px]">
            You may also like
          </div>
          <div className="ml-auto my-auto">
            <div className="flex gap-[8px]">
              <PrevArrow onClick={() => sliderRef?.current?.slickPrev()} />
              <NextArrow onClick={() => sliderRef?.current?.slickNext()} />
            </div>
          </div>
        </div>
        <div className="px-[120px] mobile:px-[20px]">
          <Slider ref={sliderRef} {...settings}>
            {filteredSolutions.map((solution, index) => (
              <div
                className="flex items-center justify-center md:w-[50%] lg:w-[32%] w-full p-4"
                key={index}
              >
                <div className="relative group w-full h-full">
                  <img
                    src={solution.solution_img}
                    alt="Solutions"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-0 left-0 w-full h-full opacity-0 group-hover:opacity-[92%] bg-[#016EB8] transition duration-300">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white flex flex-col gap-[12px] text-center">
                      <span className="text-[18px] leading-[23px]">
                        {solution.solution_name}
                      </span>
                      <span className="text-[13px] leading-[18px]">
                        {solution.solution_desc}
                      </span>
                      <DefaultButton
                        onClick={() => {
                          setActiveSolution(solution);
                          navigate("/solution/show");
                          window.location.reload();
                        }}
                        setGallery={activeSolution}
                        isOverlay={true}
                      >
                        Learn More
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default SolutionsYMAL;
