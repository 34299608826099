import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Solutions from "./screens/Solutions";

import Hero from "./screens/Hero";
import Accomplishments from "./screens/Accomplishments";
import Partnerships from "./screens/Partnerships";
import AboutUs from "./screens/AboutUs";
import Careers from "./screens/Careers";
import ContactUs from "./screens/ContactUs";
import SolutionShow from "./components/Solutions/Show/SolutionShow";
import { SolutionsProvider } from "./contexts/SolutionsContext";
import { CloudinaryContext, Transformation, Image } from "cloudinary-react";
function App() {
  return (
    <CloudinaryContext cloudName="dvfu8fxlg">
      <SolutionsProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Hero />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/solution/show" element={<SolutionShow />} />
            <Route path="/accomplishments" element={<Accomplishments />} />
            <Route path="/partnerships" element={<Partnerships />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </Router>
      </SolutionsProvider>
    </CloudinaryContext>
  );
}

export default App;
